import Locale from "@/api/locale";

export default {
  data() {
    return {
      isErrorCode: false,
      timer: 91,
      timerText: "1:31",
      telCountry: Locale.TEXTS.rus,
      telMask: "+7 (###) ### ## ##",
      telPlaceholder: "+7 (000) 000 00 00",
      isOpenTelCountry: false,
      isButtonDisabled: true,
    };
  },
  methods: {
    setTimer() {
      this.timer -= 1;
      if (this.timer > 60) {
        let second = this.timer - 60;
        if (second < 10) second = "0" + second;
        this.timerText = "1:" + second;
      } else if (this.timer < 10) {
        this.timerText = "0:0" + this.timer;
      } else this.timerText = "0:" + this.timer;
      if (this.timer > 0) setTimeout(this.setTimer, 1000);
    },
    selectTelCountry(value) {
      this.isOpenTelCountry = false;
      this.telCountry = value;
      if (value === Locale.TEXTS.rus) {
        this.telMask = "+7 (###) ### ## ##";
        this.telPlaceholder = "+7 (000) 000 00 00";
      } else if (value === Locale.TEXTS.kz) {
        this.telMask = "+7 (7##) ### ## ##";
        this.telPlaceholder = "+7 (700) 000 00 00";
      } else if (value === Locale.TEXTS.bl) {
        this.telMask = "+375 (##) ###-####";
        this.telPlaceholder = "+375 (00) 000-0000";
      } else if (value === Locale.TEXTS.uk) {
        this.telMask = "+380 (##) ###-####";
        this.telPlaceholder = "+380 (00) 000-0000";
      } else if (value === Locale.TEXTS.mol) {
        this.telMask = "+373 ####-####";
        this.telPlaceholder = "+373 0000-0000";
      } else if (value === Locale.TEXTS.tur) {
        this.telMask = "+90 (###) ###-####";
        this.telPlaceholder = "+90 (000) 000-0000";
      } else if (value === Locale.TEXTS.bul) {
        this.telMask = "+359 (###) ###-###";
        this.telPlaceholder = "+359 (000) 000-000";
      }
    },
    clearTel() {
      if (this.phone === "+7 (8") this.phone = "+7 (";
      let value = this.phone.replace(/[^\d]/g, "");
      if (value.length > 10) this.isButtonDisabled = false;
      else this.isButtonDisabled = true;
    },
    setFocus() {
      this.isErrorCode = false;
      let that = this;
      setTimeout(function () {
        if (that.code.length === 4) that.confirmPhone();
      }, 500);
    },
  },
};
