import axios from "axios";
import Const from "@/api/const";

const auth_token = localStorage.getItem("auth_token");

const instance = axios.create({
  baseURL: Const.API_URL,
});

if (auth_token) {
  instance.defaults.headers.common["Authorization"] = `Token ${auth_token}`;
}

export default instance;
