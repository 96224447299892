<script>
import api from "../api";

export default {
  created() {
    api.auth.logoutToken();
    localStorage.removeItem("auth_token");
    localStorage.removeItem("profile_id");
    localStorage.removeItem("profile_firstName");
    localStorage.removeItem("profile_middleName");
    localStorage.removeItem("profile_lastName");
    localStorage.removeItem("profile_birthday");
    localStorage.removeItem("profile_gender");
    localStorage.removeItem("profile_email");
    localStorage.removeItem("profile_phone");
    localStorage.removeItem("profile_avatar");
    localStorage.removeItem("profile_telegram");
    localStorage.removeItem("profile_instagram");
    localStorage.removeItem("profile_vk");
    localStorage.removeItem("profile_comment");
    localStorage.removeItem("isPageCheckout");
    window.location.href = "/";
  },
};
</script>
