export default function (instance) {
  return {
    register(payload) {
      return instance.post("api/v1/register/", payload);
    },
    checkPhone(payload) {
      return instance.post("api/v1/checkPhone/", payload);
    },
    editPhone(payload) {
      return instance.post("api/v1/editPhone/", payload);
    },
    editProfile(payload) {
      return instance.post("api/v1/editProfile/", payload);
    },
    getProfile() {
      return instance.get("api/v1/getProfile/");
    },
    uploadFile(payload) {
      return instance.post("api/v1/uploadFile/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    logoutToken() {
      return instance.post("auth/token/logout/");
    },
  };
}
