<template>
  <div id="app">
    <span class="ubuntu-bold">Ubuntu-Bold</span>
    <span class="ubuntu-light">Ubuntu-Light</span>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style>
@import "@/assets/css/main.css";
@import "@/assets/css/cart.css";
@import "@/assets/css/page.css";
@import "@/assets/css/modal.css";

.ubuntu-bold {
  position: absolute;
  top: -9999px;
  left: -9999px;
  font-family: "Ubuntu-Bold";
}

.ubuntu-light {
  position: absolute;
  top: -9999px;
  left: -9999px;
  font-family: "Ubuntu-Light";
}
</style>
