<template>
  <BlogPage
    :blogCategory="blogCategory"
    :authToken="authToken"
    :profile="profile"
    :slug="slug"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import BlogPage from "@/pages/BlogPage.vue";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    BlogPage,
  },
  data() {
    return {
      blogCategory: {
        name: Locale.TEXTS.blogTitle + " UltraBalance",
        id: null,
      },
      slug: "blog",
    };
  },
  created() {
    document.title = Locale.TEXTS.blogTitle;
  },
};
</script>
