<template>
  <span
    class="image"
    :style="{ background: product.images[0].image.background_color }"
  >
    <img :src="product.images[0].image.image_object_middle" />
  </span>
  <span class="info">
    <span class="name">{{ product.headline_preview }}</span>
    <span v-if="product.discount_price" class="price">
      <span class="new">
        {{ formatPrice(product.discount_price) }}
        {{ locale.TEXTS.currencyText }}
      </span>
      <span class="old">
        {{ formatPrice(product.website_price) }}
        {{ locale.TEXTS.currencyText }}
      </span>
    </span>
    <span v-else class="price">
      <span class="new">
        {{ formatPrice(product.website_price) }}
        {{ locale.TEXTS.currencyText }}
      </span>
    </span>
  </span>
</template>
<script>
import productMixin from "@/mixins/productMixin";
import Locale from "@/api/locale";

export default {
  mixins: [productMixin],
  props: {
    product: Object,
  },
  data() {
    return {
      locale: Locale,
    };
  },
};
</script>
