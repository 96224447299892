<template>
  <div class="info">
    <span class="avatar">
      <img v-if="review.avatar" :src="review.avatar" />
      <img v-else src="@/assets/images/shop-default-avatar.svg" />
    </span>
    <span class="name">
      <!-- <span class="text">{{ review.name }}, {{ review.date }}</span>-->
      <span class="text">{{ review.name }}</span>
      <span class="stars" v-html="review.stars"></span>
    </span>
  </div>
  <div class="content">{{ review.content }}</div>
  <div class="images">
    <swiper
      class="swiper"
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :loop="false"
      :navigation="true"
      :allow-touch-move="valueAllowTouchMove"
      :style="{
        '--swiper-navigation-color': '#000',
        '--swiper-navigation-size': '32px',
      }"
    >
      <swiper-slide
        v-for="(image, index) in review.images"
        :key="image"
        @click.stop="(isOpenPopupGallery = true), (initialSlide = index)"
      >
        <div
          class="image"
          :style="{
            background: 'url(' + image.file.file_object + ')',
          }"
        ></div>
      </swiper-slide>
    </swiper>
  </div>

  <div v-if="isOpenPopupGallery" class="gallery-popup shop-popup unselectable">
    <div class="content">
      <span class="close" @click="isOpenPopupGallery = null">
        <img src="@/assets/images/shop-close.svg" />
      </span>
      <swiper
        class="swiper"
        :modules="modules"
        :slides-per-view="1"
        :initialSlide="initialSlide"
        :loop="true"
        :pagination="{ type: 'bullets' }"
        :navigation="review.images.length > 1 ? true : false"
        :allow-touch-move="valueAllowTouchMove"
        :keyboard="{ enabled: true }"
        :style="{
          '--swiper-navigation-color': '#000',
          '--swiper-navigation-size': '32px',
        }"
      >
        <swiper-slide
          v-for="item in review.images"
          :key="item"
          :class="{ zoom: item.isZoom }"
          @click="item.isZoom ? (item.isZoom = false) : (item.isZoom = true)"
        >
          <span class="image-item">
            <img :src="item.file.file_object" />
          </span>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import { Pagination, Navigation, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default {
  mixins: [mainMixin],
  props: {
    review: Object,
    valueAllowTouchMove: Boolean,
    slidesPerView: Number,
    spaceBetween: Number,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Keyboard],
    };
  },
  data() {
    return {
      isOpenPopupGallery: false,
      initialSlide: false,
    };
  },
  created() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenPopupGallery = false;
        that.isOpenPopup(false);
      }
    });
  },
};
</script>
