import Locale from "@/api/locale";

export default {
  methods: {
    dateFormat(value) {
      let result = "";
      let date = new Date(value);
      let month = date.getMonth();
      if (month === 0) result = Locale.TEXTS.month1;
      if (month === 1) result = Locale.TEXTS.month2;
      if (month === 2) result = Locale.TEXTS.month3;
      if (month === 3) result = Locale.TEXTS.month4;
      if (month === 4) result = Locale.TEXTS.month5;
      if (month === 5) result = Locale.TEXTS.month6;
      if (month === 6) result = Locale.TEXTS.month7;
      if (month === 7) result = Locale.TEXTS.month8;
      if (month === 8) result = Locale.TEXTS.month9;
      if (month === 9) result = Locale.TEXTS.month10;
      if (month === 10) result = Locale.TEXTS.month11;
      if (month === 11) result = Locale.TEXTS.month12;
      result += " ";
      result += date.getDate();
      result += ", ";
      result += date.getFullYear();
      return result;
    },
    dateFormatString(value) {
      let result = "";
      let date = new Date(value);
      if (date.getDate() < 10) result += "0";
      result += date.getDate();
      result += ".";
      if (date.getMonth() + 1 < 10) result += "0";
      result += date.getMonth() + 1;
      result += ".";
      result += date.getFullYear();
      return result;
    },
    async clickShare() {
      let shareObj = {
        title: document.title,
        url: window.location.href,
      };
      await navigator.share(shareObj);
    },
    replace_letter(str) {
      let values = new Array(
        ["q", "й"],
        ["w", "ц"],
        ["e", "у"],
        ["r", "к"],
        ["t", "е"],
        ["y", "н"],
        ["u", "г"],
        ["i", "ш"],
        ["o", "щ"],
        ["p", "з"],
        ["[", "х"],
        ["]", "ъ"],
        ["a", "ф"],
        ["s", "ы"],
        ["d", "в"],
        ["f", "а"],
        ["g", "п"],
        ["h", "р"],
        ["j", "о"],
        ["k", "л"],
        ["l", "д"],
        [";", "ж"],
        ['"', "э"],
        ["z", "я"],
        ["x", "ч"],
        ["c", "с"],
        ["v", "м"],
        ["b", "и"],
        ["n", "т"],
        ["m", "ь"],
        [",", "б"],
        [".", "ю"],
        ["/", "."]
      );

      str = str.toLowerCase();
      let newStr = new String();
      for (let i = 0; i < str.length; i++) {
        let ch = str.charAt(i);
        let newCh = "";
        for (let j = 0; j < values.length; j++) {
          if (ch === values[j][0]) newCh = values[j][1];
        }
        newStr += newCh;
      }
      return newStr;
    },
  },
};
