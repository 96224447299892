<template>
  <HeaderBlock :isHideSearch="false" />
  <section class="cart-section" @click.stop="promoCodeInfo = false">
    <h1>
      Корзина
      <span v-if="countBasket">{{ countBasket }}</span>
    </h1>
    <div v-if="productBaskets.length > 0" class="products-cart">
      <div class="left">
        <div v-for="item in productBaskets" :key="item" class="item">
          <a
            class="image"
            :style="{
              background: item.product.images[0].image.background_color,
            }"
            :href="getUrlProduct(item.product)"
          >
            <img :src="item.product.images[0].image.image_object_middle" />
          </a>
          <div class="info">
            <span class="name" v-if="item.type === 5">
              Пластиковая подарочная карта номиналом
              {{ formatPrice(item.product.headline_preview) }} рублей
            </span>
            <span class="name" v-else-if="item.type === 6">
              Виртуальная подарочная карта номиналом
              {{ formatPrice(item.product.headline_preview) }} рублей
            </span>
            <span class="name" v-else>
              {{ item.product.headline_preview }}
            </span>
            <span
              v-if="item.product.id === productPackage"
              class="product-quantity"
            >
              Бесплатно за каждые 2000 руб. в чеке. <br />
              Далее по 199 руб за 1 шт.
            </span>
            <span v-else-if="item.product.type === 1" class="product-quantity">
              {{ item.product.feature_product_quantity }}
              <span v-if="item.product.release_form">
                {{
                  getDeclination(
                    item.product.feature_product_quantity,
                    item.product.release_form
                  )
                }}
              </span>
            </span>
            <span v-else-if="item.product.type === 2" class="product-quantity">
              {{ item.product.products[0].quantity }}
              x
              {{ item.product.products[0].product.feature_product_quantity }}
              <span v-if="item.product.products[0].product.release_form">
                {{
                  getDeclination(
                    item.product.products[0].product.feature_product_quantity,
                    item.product.products[0].product.release_form
                  )
                }}
              </span>
            </span>
            <span v-else v-html="item.productQuantity" class="product-quantity">
            </span>
            <span class="price">
              <span class="new">{{ formatPrice(item.priceTotal) }} руб.</span>
              <span v-if="item.priceOldTotal" class="old">
                {{ formatPrice(item.priceOldTotal) }} руб.
              </span>
            </span>
            <span class="mobile-name" v-if="item.product.id === productPackage">
              {{ item.product.headline_preview }}
            </span>
            <span class="mobile-name" v-else>
              {{ item.product.headline_preview }},
              {{ item.product.feature_product_quantity }}
            </span>
            <span
              v-if="item.product.type === 3"
              v-html="item.productQuantity"
              class="product-quantity product-quantity-3"
            ></span>
            <div class="delay">
              <span
                class="minus"
                @click.stop="clickEditBasketProduct(item, item.quantity - 1)"
              ></span>
              <span class="quantity">{{ item.quantity }} шт.</span>
              <span
                class="plus"
                @click.stop="clickEditBasketProduct(item, item.quantity + 1)"
                :class="
                  'basket-cart-' +
                  item.product.id +
                  ' basket-' +
                  item.product.id
                "
              ></span>
            </div>
            <span
              class="delete"
              @click.stop="clickEditBasketProduct(item, 0)"
            ></span>
          </div>
          <div class="reject" v-if="item.quantity === 0">
            <button @click.stop="clickEditBasketProduct(item, 1)">
              Восстановить
            </button>
          </div>
        </div>
      </div>
      <div class="right">
        <CartInfoBlock
          v-if="isDisplayCartInfoBlock"
          :products="productBaskets"
          :city="city"
          :isOnlyVirtual="isOnlyVirtual"
        />
        <button @click.stop="clickCheckout" class="cart-button-order">
          Оформить заказ
        </button>
        <hr />
        <ListDeliveryBlock @changeCity="city = $event" />
        <hr />
        <span class="agree">
          Нажимая на кнопку «Оформить заказ» вы даёте свое согласие на обработку
          <a href="/policy" target="_blank">персональных данных</a>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="empty-cart">
        <h2>Ваша корзина пуста</h2>
        <p>Перейдите в каталог, чтобы выбрать необходимые вам продукты</p>
        <button @click="$router.push({ name: 'catalog' })">В каталог</button>
      </div>
      <div v-if="productsViews.length > 0" class="products-views">
        <h2>Вы смотрели:</h2>
        <div class="items">
          <ProductItemBlock
            v-for="item in productsViews"
            :product="item"
            :key="item"
            :isFavorite="false"
            :isBasket="false"
            :type="''"
            :sourcePage="11"
          />
        </div>
      </div>
    </div>
  </section>
  <FooterMinBlock />
  <ErrorTooltipBlock
    v-if="errorTooltip"
    :header="'Ошибка'"
    :text="'Недостаточно в наличие'"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterMinBlock from "@/components/FooterMinBlock.vue";
import ListDeliveryBlock from "@/components/ListDeliveryBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import CartInfoBlock from "@/components/CartInfoBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import api from "@/api";

export default {
  mixins: [mainMixin, profileMixin, productMixin],
  components: {
    CartInfoBlock,
    HeaderBlock,
    FooterMinBlock,
    ListDeliveryBlock,
    ProductItemBlock,
    ErrorTooltipBlock,
  },
  data() {
    return {
      isDisplayCartInfoBlock: false,
      productsViews: [],
      productBaskets: [],
      countBasket: 0,
      city: null,
      productPackage: 102,
      errorTooltip: null,
      promoCodeInfo: false,
      isOnlyVirtual: true,
    };
  },

  async created() {
    window.scrollTo({ top: 2, left: 0, behavior: "smooth" });
    localStorage.removeItem("redirectBackPayment");
    document.title = "Корзина";
    document.getElementsByTagName("meta")["description"].content = "Корзина";
    this.getCart();
  },
  watch: {
    city(value, oldValue) {
      if (!oldValue) return false;
      this.totalBasket();
    },
  },
  methods: {
    async getCart() {
      if (this.authToken) {
        try {
          const result = await api.shop.getShopProductUserBaskets();
          for (let item in result["data"]["results"]) {
            item = result["data"]["results"][item];
            let product = item["product"];
            let price = product["website_price"];
            let priceOld = null;
            let priceOldTotal = null;
            if (product["discount_price"]) {
              price = product["discount_price"];
              priceOld = product["website_price"];
              priceOldTotal = priceOld * item["quantity"];
            }
            let productQuantity = "";
            if (product["type"] === 3) {
              for (let productTo in product["products"]) {
                productTo = product["products"][productTo];
                let release_form = "";
                if (productTo["product"]["release_form"]) {
                  release_form = this.getDeclination(
                    productTo["product"]["feature_product_quantity"],
                    productTo["product"]["release_form"]
                  );
                }
                if (productQuantity !== "") productQuantity += "<br />";
                productQuantity +=
                  productTo["quantity"] +
                  "x " +
                  productTo["product"]["headline_preview"] +
                  "<span>, </span><span class='value'>" +
                  productTo["product"]["feature_product_quantity"] +
                  " " +
                  release_form +
                  "</span>";
              }
            }
            this.productBaskets.push({
              count_available: product["count_available"],
              type: product["type"],
              product: product,
              price: price,
              priceTotal: price * item["quantity"],
              priceOld: priceOld,
              priceOldTotal: priceOldTotal,
              quantity: item["quantity"],
              productQuantity: productQuantity,
            });
          }
          if (this.productBaskets.length === 0) this.getProductViews();
          else this.totalBasket();
        } catch (error) {
          console.log(error);
        }
      } else {
        for (let i = 0; i < localStorage.length; i++) {
          let values = localStorage.key(i).split("shop_basketProduct_");
          if (values.length > 1) {
            try {
              const result = await api.shop.getShopProductsInfo(values[1]);
              console.log(result);
              let product = result["data"]["results"][0];
              let price = product["website_price"];
              let priceOld = null;
              let priceOldTotal = null;
              let quantity = localStorage.getItem(
                "shop_basketProduct_" + values[1]
              );
              if (product["discount_price"]) {
                price = product["discount_price"];
                priceOld = product["website_price"];
                priceOldTotal = priceOld * Number(quantity);
              }
              let productQuantity = "";
              if (product["type"] === 3) {
                for (let productTo in product["products"]) {
                  productTo = product["products"][productTo];
                  if (productQuantity !== "") productQuantity += "<br />";
                  productQuantity +=
                    productTo["quantity"] +
                    "x " +
                    productTo["product"]["headline_preview"];
                }
              }
              this.productBaskets.push({
                count_available: product["count_available"],
                type: product["type"],
                price: price,
                priceTotal: price * Number(quantity),
                priceOld: priceOld,
                priceOldTotal: priceOldTotal,
                product: product,
                quantity: Number(quantity),
                productQuantity: productQuantity,
              });
            } catch (error) {
              console.log(error);
            }
          }
        }
        if (this.productBaskets.length === 0) this.getProductViews();
        else this.totalBasket();
      }
    },
    async getProductViews() {
      if (this.authToken) {
        try {
          const result = await api.shop.getShopProductUserViews();
          for (let item in result["data"]["results"]) {
            item = result["data"]["results"][item]["product"];
            this.productsViews.push(item);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        let data = localStorage.getItem("shop_viewsProducts");
        if (data) {
          data = data.split(",");
          let productsId = {};
          for (let item in data) {
            item = data[item];
            if (productsId[item]) continue;
            productsId[item] = true;
            try {
              const result = await api.shop.getShopProductsInfo(item);
              let product = result["data"]["results"][0];
              this.productsViews.push(product);
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    },
    async clickEditBasketProduct(item, quantity) {
      if (item["type"] === 5 && quantity > item["count_available"]) {
        this.errorTooltip = true;
        return false;
      }
      let product_id = item["product"]["id"];
      if (this.authToken) {
        try {
          let data = {
            product_id: product_id,
            quantity: quantity,
          };
          let mailingMassId = localStorage.getItem("mailingMassId");
          if (mailingMassId) data.mailing_mass_id = mailingMassId;
          let mailingTriggerId = localStorage.getItem("mailingTriggerId");
          if (mailingTriggerId) data.mailing_trigger_id = mailingTriggerId;
          await api.shop.postShopProductUserBasket(data);
          item.quantity = quantity;
          this.checkAllBasket();
        } catch (error) {
          console.log(error);
        }
      } else if (quantity === 0) {
        localStorage.removeItem("shop_basketProduct_" + product_id);
        item.quantity = quantity;
        this.checkAllBasket();
      } else {
        localStorage.setItem("shop_basketProduct_" + product_id, quantity);
        item.quantity = quantity;
        this.checkAllBasket();
      }
      this.trackerClickBasket(product_id, quantity);
      this.searchSessionBasket(product_id, quantity);
      if (quantity === 0) {
        let dataItem = {
          ecommerce: {
            currencyCode: "RUB",
            remove: {
              products: [
                {
                  id: String(product_id),
                  quantity: 1,
                },
              ],
            },
          },
        };
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataItem);
      }
    },
    checkAllBasket() {
      let count = 0;
      for (let item in this.productBaskets) {
        count += this.productBaskets[item].quantity;
      }
      if (count < 1) {
        localStorage.removeItem("cart_promoCode");
      }
      this.totalBasket();
    },
    clickCheckout() {
      location.href = "/checkout";
    },
    totalBasket() {
      this.isOnlyVirtual = true;
      for (let item in this.productBaskets) {
        if (this.productBaskets[item].quantity === 0) continue;
        if (this.productBaskets[item].type !== 6) {
          this.isOnlyVirtual = false;
          break;
        }
      }
      this.isDisplayCartInfoBlock = false;
      this.$nextTick(() => {
        this.isDisplayCartInfoBlock = true;
      });
    },
    getUrlProduct(product) {
      let url = "";
      if (product.type === 5) url = "/catalog/gift-card";
      else if (product.type === 6) url = "/catalog/gift-card";
      else url = "/catalog/" + product.shop_page[0].url;
      return url;
    },
  },
};
</script>
