<template>
  <ArticlePage
    v-if="article"
    :article="article"
    :authToken="authToken"
    :profile="profile"
    :slug="slug"
  />
  <BlogPage
    v-if="blogCategory"
    :blogCategory="blogCategory"
    :authToken="authToken"
    :profile="profile"
    :slug="slug"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import ArticlePage from "@/pages/ArticlePage.vue";
import BlogPage from "@/pages/BlogPage.vue";
import api from "@/api";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    BlogPage,
    ArticlePage,
  },
  data() {
    return {
      article: null,
      slug: "",
      blogCategory: null,
    };
  },
  async created() {
    try {
      this.slug = this.$route.params.path_blog;
      const result = await api.shop.getShopPageFromPath(this.slug);
      let page = result["data"]["results"][0];
      if (page["blog_article"]) this.article = page["blog_article"];
      else if (page["blog_category"]) {
        this.blogCategory = page["blog_category"];
      } else window.location.href = "/blog";
      document.title = page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/blog";
    }
  },
};
</script>
