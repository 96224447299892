<template>
  <section class="subscriber-section">
    <div class="subscriber-block">
      <span>
        {{ locale.TEXTS.subscriberText }}
        <a :href="footerUrlSubscriber" target="_blank">
          {{ locale.TEXTS.subscriberUrl }}
        </a>
      </span>
      <a :href="footerUrlSubscriber" target="_blank">
        {{ locale.TEXTS.subscriberButton }}
      </a>
    </div>
  </section>
  <footer class="footer-full">
    <div class="footer-content">
      <div class="section-first">
        <a class="logo" href="/">
          <img :src="footerLogo" />
        </a>
        <span class="description about">{{ footerAbout }}</span>
        <span class="description requisites">{{ footerRequisites }}</span>
        <span class="description">{{ footerAddress }}</span>
        <span class="description phone">{{ footerPhone }}</span>
        <span class="description email">{{ footerEmail }}</span>
        <span class="href-to" v-for="item in footerPages['1']" :key="item.name">
          <a target="_blank" :href="item.url">{{ item.name }}</a>
        </span>
      </div>
      <div class="section-other">
        <span class="headline">{{ footerSectionSecondTitle }}</span>
        <span class="href-to" v-for="item in footerPages['2']" :key="item.name">
          <a target="_blank" :href="item.url">{{ item.name }}</a>
        </span>
      </div>
      <div class="section-other">
        <span class="headline">{{ footerSectionThirdTitle }}</span>
        <span class="href-to" v-for="item in footerPages['3']" :key="item.name">
          <a target="_blank" :href="item.url">{{ item.name }}</a>
        </span>
      </div>
      <div class="section-other">
        <span class="headline">{{ footerSectionFourthTitle }}</span>
        <span class="href-to" v-for="item in footerPages['4']" :key="item.name">
          <a target="_blank" :href="item.url">{{ item.name }}</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import api from "@/api";
import Locale from "@/api/locale";

export default {
  data() {
    return {
      footerLogo: "",
      footerUrlSubscriber: "",
      footerAbout: "",
      footerAddress: "",
      footerEmail: "",
      footerPhone: "",
      footerRequisites: "",
      footerSectionFourthTitle: "",
      footerSectionSecondTitle: "",
      footerSectionThirdTitle: "",
      footerPages: {
        1: [],
        2: [],
        3: [],
        4: [],
      },
      locale: Locale,
    };
  },
  async created() {
    try {
      let result = await api.shop.getShopFooter();
      result = result["data"]["results"][0];
      this.footerLogo = result["logo"]["image_object"];
      this.footerUrlSubscriber = result["url_subscriber"];
      this.footerAbout = result["about"];
      this.footerAddress = result["address"];
      this.footerEmail = result["email"];
      this.footerPhone = result["phone"];
      this.footerRequisites = result["requisites"];
      this.footerSectionFourthTitle = result["section_fourth_title"];
      this.footerSectionSecondTitle = result["section_second_title"];
      this.footerSectionThirdTitle = result["section_third_title"];
      for (let item in result["pages"]) {
        item = result["pages"][item];
        let url = item["external_reference"];
        if (item["page"]) {
          url = "";
          if (item["page"]["type"] === 3) url = "/catalog";
          if (item["page"]["type"] === 4) url = "/blog";
          if (item["page"]["type"] === 5) url = "/blog";
          if (item["page"]["type"] === 6) url = "/catalog";
          url += "/" + item["page"]["url"];
        }
        this.footerPages[item["section"]].push({
          name: item["name"],
          url: url,
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
