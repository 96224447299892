<script>
import api from "@/api";

export default {
  async created() {
    let userAgent = window.navigator.userAgent;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let userHash = localStorage.getItem("userHash");
    if (!userHash) userHash = null;
    let payload = {
      user_hash: userHash,
      stream_url: this.$route.params.path_stream,
      browser: "unknown",
      referrer: document.referrer,
      device: "desktop",
      http_user_agent: userAgent,
      operating_system: "unknown",
      timezone: new Date().getTimezoneOffset(),
      language: window.navigator.userLanguage || window.navigator.language,
      screen_size: window.screen.width + "x" + window.screen.height,
      connection_type: "unknown",
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_content: urlParams.get("utm_content"),
      utm_term: urlParams.get("utm_term"),
      price: urlParams.get("price"),
    };
    if (window.navigator.connection) {
      switch (navigator.connection.type) {
        case navigator.connection.WIFI:
          payload.connection_type = "wifi";
          break;
        case navigator.connection.ETHERNET:
          payload.connection_type = "ethernet";
          break;
        case navigator.connection.CELL_2G:
          payload.connection_type = "cell 2G";
          break;
        case navigator.connection.CELL_3G:
          payload.connection_type = "cell 3G";
          break;
        default:
          payload.connection_type = "Missing";
      }
    }
    if (userAgent.indexOf("Opera") !== -1) payload.browser = "Opera";
    else if (userAgent.indexOf("OPR") !== -1) payload.browser = "Yandex";
    else if (userAgent.indexOf("YaBrowser") !== -1) payload.browser = "Yandex";
    else if (userAgent.indexOf("Yowser") !== -1) payload.browser = "Yandex";
    else if (userAgent.indexOf("Chrome") !== -1) payload.browser = "Chrome";
    else if (userAgent.indexOf("Safari") !== -1) payload.browser = "Safari";
    else if (userAgent.indexOf("Firefox") !== -1) payload.browser = "Firefox";
    else if (
      userAgent.indexOf("MSIE") !== -1 ||
      !!document.documentMode === true
    ) {
      payload.browser = "IE";
    }
    if (/Mobi/.test(userAgent)) payload.device = "mobile";
    if (userAgent.indexOf("Windows NT 10.0") !== -1) {
      payload.operating_system = "Windows 10";
    }
    if (userAgent.indexOf("Windows NT 6.3") !== -1) {
      payload.operating_system = "Windows 8.1";
    }
    if (userAgent.indexOf("Windows NT 6.2") !== -1) {
      payload.operating_system = "Windows 8";
    }
    if (userAgent.indexOf("Windows NT 6.1") !== -1) {
      payload.operating_system = "Windows 7";
    }
    if (userAgent.indexOf("Windows NT 6.0") !== -1) {
      payload.operating_system = "Windows Vista";
    }
    if (userAgent.indexOf("Windows NT 5.1") !== -1) {
      payload.operating_system = "Windows XP";
    }
    if (userAgent.indexOf("Windows NT 5.0") !== -1) {
      payload.operating_system = "Windows 2000";
    }
    if (userAgent.indexOf("Mac") !== -1) payload.operating_system = "Mac iOS";
    if (userAgent.indexOf("X11") !== -1) payload.operating_system = "UNIX";
    if (userAgent.indexOf("Linux") !== -1) payload.operating_system = "Linux";
    try {
      const result = await api.shop.postShopTrackerClick(payload);
      localStorage.setItem("clickHash", result["data"]["click_hash"]);
      localStorage.setItem("userHash", result["data"]["user_hash"]);
      localStorage.setItem("clickAttribution", new Date());
      sessionStorage.setItem("clickAttributionSession", "1");
      let redirectUrl = "";
      if (payload.utm_source) {
        if (redirectUrl === "") redirectUrl += "?";
        else redirectUrl += "&";
        redirectUrl += "utm_source=" + payload.utm_source;
      }
      if (payload.utm_medium) {
        if (redirectUrl === "") redirectUrl += "?";
        else redirectUrl += "&";
        redirectUrl += "utm_medium=" + payload.utm_medium;
      }
      if (payload.utm_campaign) {
        if (redirectUrl === "") redirectUrl += "?";
        else redirectUrl += "&";
        redirectUrl += "utm_campaign=" + payload.utm_campaign;
      }
      if (payload.utm_content) {
        if (redirectUrl === "") redirectUrl += "?";
        else redirectUrl += "&";
        redirectUrl += "utm_content=" + payload.utm_content;
      }
      if (payload.utm_term) {
        if (redirectUrl === "") redirectUrl += "?";
        else redirectUrl += "&";
        redirectUrl += "utm_term=" + payload.utm_term;
      }
      window.location.href = "/" + result["data"]["redirect_url"] + redirectUrl;
    } catch (error) {
      console.log(error);
      window.location.href = "/";
    }
  },
};
</script>
