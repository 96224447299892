<template>
  <ProductPage
    v-if="product"
    :productProp="product"
    :profile="profile"
    :slug="slug"
  />
  <CatalogPage
    v-if="productCategory"
    :productCategory="productCategory"
    :profile="profile"
    :slug="slug"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import ProductPage from "@/pages/ProductPage.vue";
import CatalogPage from "@/pages/CatalogPage.vue";
import api from "@/api";

export default {
  mixins: [mainMixin, profileMixin],
  components: {
    ProductPage,
    CatalogPage,
  },
  data() {
    return {
      product: null,
      productCategory: null,
      slug: "",
    };
  },
  async created() {
    try {
      this.slug = this.$route.params.path_catalog;
      const result = await api.shop.getShopPageFromPath(this.slug);
      let page = result["data"]["results"][0];
      if (page["product"]) this.product = page["product"];
      else if (page["product_category"]) {
        this.productCategory = page["product_category"];
      } else window.location.href = "/catalog";
      document.title = page["title"];
      document.getElementsByTagName("meta")["description"].content =
        page["description"];
    } catch (error) {
      window.location.href = "/catalog";
    }
  },
};
</script>
