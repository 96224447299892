<template>
  <div class="preview-products-popup shop-popup">
    <div class="content">
      <img
        @click="closePopup"
        src="@/assets/images/shop-close-white.svg"
        class="close"
      />
      <swiper
        class="swiper"
        :modules="modules"
        direction="vertical"
        :initialSlide="initialSlide"
        @slideChange="changeValueDelay"
      >
        <swiper-slide
          v-for="(item, index) in products"
          :key="item"
          class="slide"
          v-bind:data-index="index"
        >
          <div
            class="this-product"
            :style="{ background: item.firstImage.background_color }"
          >
            <div class="product-info">
              <span class="name">{{ item.headline_preview }}</span>
              <span class="release" v-if="item.type === 1">
                {{ item.feature_product_quantity }}
                <span v-if="item.release_form">
                  {{
                    getDeclination(
                      item.feature_product_quantity,
                      item.release_form
                    )
                  }}
                </span>
              </span>
              <span class="release" v-else-if="item.type === 2">
                {{ item.products[0].quantity }}
                x
                {{ item.products[0].product.feature_product_quantity }}
                <span v-if="item.products[0].product.release_form">
                  {{
                    getDeclination(
                      item.products[0].product.feature_product_quantity,
                      item.products[0].product.release_form
                    )
                  }}
                </span>
              </span>
              <div v-if="item.video">
                <video
                  webkit-playsinline
                  playsinline
                  muted="true"
                  autoPlay
                  loop
                >
                  <source type="video/mp4" :src="item.video.file_object" />
                </video>
              </div>
              <div
                v-else
                :style="{
                  background:
                    'url(' + item.firstImage.image_object_middle + ')',
                }"
              ></div>
            </div>
            <div class="product-delay">
              <span v-if="item.type === 4">
                <span class="new">Цена по номиналу</span>
              </span>
              <span v-else-if="item.discount_price">
                <span class="old">
                  {{ formatPrice(item.website_price) }}
                  {{ locale.TEXTS.currencyText }}
                </span>
                <span class="new">
                  {{ formatPrice(item.discount_price) }}
                  {{ locale.TEXTS.currencyText }}
                </span>
              </span>
              <span v-else>
                <span class="new">
                  {{ formatPrice(item.website_price) }}
                  {{ locale.TEXTS.currencyText }}
                </span>
              </span>
              <a :href="'/catalog/' + item.shop_page[0].url">О продукте</a>
              <button v-if="item.productBasket > 0" @click.stop="nextOnCart">
                <span>
                  {{ item.productBasket }}
                </span>
              </button>
              <button
                v-else
                @click.stop="
                  clickBasketProduct(item, 1, true, sourcePage, null)
                "
                :class="
                  'basket-' +
                  type +
                  '-' +
                  item.id +
                  ' basket-' +
                  item.id +
                  ' ' +
                  type +
                  '-mobile'
                "
              ></button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import productMixin from "@/mixins/productMixin";
import Locale from "@/api/locale";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css";
import "swiper/css/navigation";
import Const from "@/api/const";

export default {
  mixins: [productMixin],
  props: {
    products: Object,
    initialSlide: Number,
    sourcePage: Number,
    type: String,
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      apiUrl: null,
      locale: Locale,
    };
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  created() {
    this.apiUrl = Const.API_URL;
  },
  methods: {
    closePopup() {
      document.body.style.overflow = "auto";
      history.pushState(null, null, this.$parent.lastCatalogUrl);
      this.$parent.productPreview = null;
      this.$parent.lastCatalogUrl = null;
    },
    changeValueDelay() {
      let that = this;
      setTimeout(function () {
        let slide = document.querySelector(
          ".preview-products-popup .swiper-slide-active"
        );
        if (!slide) return false;
        slide = Number(slide.getAttribute("data-index"));
        let hrefUrl = "/catalog/" + that.products[slide].shop_page[0].url;
        history.pushState(
          null,
          null,
          location.protocol + "//" + location.host + hrefUrl
        );
      }, 100);
    },
    nextOnCart() {
      window.location.href = "/cart";
    },
  },
};
</script>
