<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'partner'" />
    <div class="block-right personal-partner">
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>Партнерская программа</h1>
      <div class="block-items" v-if="isLoaded">
        <div class="block-item">
          <span>
            <span>{{ formatPrice(allPayment) }}</span>
            <span>Всего начислений, руб.</span>
          </span>
          <span>
            <span>{{ formatPrice(waitPayment) }}</span>
            <span>Ожидает выплаты, руб.</span>
          </span>
          <span>
            <span>{{ formatPrice(balance) }}</span>
            <span>Доступный баланс, руб.</span>
          </span>
          <button @click.stop="isOpenPayment = true">Заказать выплату</button>
          <a @click.stop="isOpenStories = true">Все операции</a>
        </div>
        <div class="block-item">
          <span v-if="promoCode" class="copy" @click="doCopy">
            {{ promoCode.value }}
          </span>
          <p v-if="promoCode">
            Промокод партнера дает скидку {{ promoCodeAmount }} для клиентов.
            <br /><br />
            Вознаграждение партнера {{ promoCode.reward }}% от итоговой суммы
            заказа. <br /><br />
            Вознаграждение начисляется после того, как клиент получит заказ.
          </p>
          <p v-else>
            Нет активных промокодов
            <br /><br />
            Свяжитесь с менеджером для активации промокода
            <br /><br />
            <a href="/" target="_blank">Связаться в Telegram</a>
            <br />
            <a href="/" target="_blank">Связаться в Whatsapp</a>
          </p>
        </div>
        <div class="block-item">
          <div v-if="isPartnerInfo">
            <div>
              <span>Форма занятости</span>
              <span v-if="info.type === 1">ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ</span>
              <span v-else>Самозанятый</span>
            </div>
            <div>
              <span>ФИО</span>
              <span>{{ info.name }}</span>
            </div>
            <div>
              <span>ИНН</span>
              <span>{{ info.inn }}</span>
            </div>
            <div>
              <span>БАНК</span>
              <span>{{ info.bank }}</span>
            </div>
            <div>
              <span>СЧЕТ</span>
              <span>{{ info.number }}</span>
            </div>
          </div>
          <p v-else>
            Добавьте банковские реквизиты ИП или Самозанятого, чтобы получать
            выплаты на р/с
          </p>
          <button v-if="isPartnerInfo" @click.stop="isOpenInfo = true">
            Изменить реквизиты
          </button>
          <button v-else @click.stop="isOpenInfo = true">
            Добавить реквизиты
          </button>
        </div>
      </div>
      <h2>Условия партнерской программы</h2>
      <h3>
        Отношения с партнерами описаны в
        <a href="/offerta-affiliate-program" target="_blank">
          агентском договоре партнерской программы (офферта) от 5 февраля 2024
          года
        </a>
      </h3>
      <div class="faq-item" :class="{ open: faq[0].isOpen }">
        <span @click.stop="openFaq(0)">
          Как заказать выплату вознаграждения
        </span>
        <img
          src="@/assets/images/shop-close-red.svg"
          v-if="faq[0].isOpen"
          @click.stop="openFaq(0)"
        />
        <img
          src="@/assets/images/shop-plus-black.svg"
          v-else
          @click.stop="openFaq(0)"
        />
        <p v-if="faq[0].isOpen">
          Чтобы заказать выплату вознаграждения необходимо указать реквизиты ИП
          или Самозанятого. После нажать на кнопку “Заказать выплату” и указать
          сумму к выплате.
          <br />
          Выплата обрабатывается в течение 7 рабочих дней. После подтверждения
          выплаты вам придет уведомление на e-mail.
          <br />
          Выплата может быть отклонена если есть ошибка в реквизитах, или
          нарушены правила
          <a href="/offerta-affiliate-program" target="_blank">
            агентского договора партнерской программы (офферта) от 5 февраля
            2024 года
          </a>
        </p>
      </div>
      <div class="faq-item" :class="{ open: faq[1].isOpen }">
        <span @click.stop="openFaq(1)">
          Как вывести вознаграждения если у меня нет ИП и самозанятости
        </span>
        <img
          src="@/assets/images/shop-close-red.svg"
          v-if="faq[1].isOpen"
          @click.stop="openFaq(1)"
        />
        <img
          src="@/assets/images/shop-plus-black.svg"
          v-else
          @click.stop="openFaq(1)"
        />
        <p v-if="faq[1].isOpen">
          Вывоз партнерского вознаграждения можно осуществить на бонусный счет
          сайта ultrabalance.ru с дополнительным вознаграждением +3%. Бонусы
          можно использовать для оплаты покупок на ultrabalance.ru
        </p>
      </div>
    </div>
  </section>
  <div class="personal-footer">
    <FooterBlock />
  </div>
  <div class="profile-popup partner-stories shop-popup" v-if="isOpenStories">
    <div class="content">
      <img
        src="@/assets/images/shop-close.svg"
        class="close"
        @click.stop="isOpenStories = false"
      />
      <div class="select-stories">
        <span
          @click.stop="popupStories = 'stories'"
          :class="{ active: popupStories === 'stories' }"
        >
          Операции
        </span>
        <span
          @click.stop="popupStories = 'payments'"
          :class="{ active: popupStories === 'payments' }"
        >
          Выплаты
        </span>
      </div>
      <div
        v-if="stories.length > 0 && popupStories === 'stories'"
        class="pp-stories"
      >
        <div class="pp-stories-items">
          <div class="header">
            <span>Дата</span>
            <span>Сумма</span>
            <span>Доступный баланс</span>
            <span>Операция</span>
          </div>
          <div v-for="item in stories" :key="item" class="item">
            <span>{{ dateFormatString(item.created_at) }}</span>
            <span v-if="item.amount === 0">0</span>
            <span v-else-if="item.type === 1">+ {{ item.amount }}</span>
            <span v-else class="write-off">- {{ item.amount }}</span>
            <span>{{ item.balance }}</span>
            <span v-html="editComment(item)"></span>
          </div>
        </div>
      </div>
      <div
        v-if="payments.length > 0 && popupStories === 'payments'"
        class="pp-payments"
      >
        <div class="pp-payments-items">
          <div class="header">
            <span>№</span>
            <span>Сумма</span>
            <span>Статус</span>
            <span>Комментарий</span>
          </div>
          <div v-for="item in payments" :key="item" class="item">
            <span>{{ profile.id }} - {{ item.id }}</span>
            <span>{{ item.amount }}</span>
            <span v-if="item.status === 1">В ожидании</span>
            <span v-else-if="item.status === 2">Выплачено</span>
            <span v-else>Отклонено</span>
            <span v-html="item.comment"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="profile-popup partner-info-popup shop-popup" v-if="isOpenInfo">
    <div class="content">
      <img
        src="@/assets/images/shop-close.svg"
        class="close"
        @click.stop="isOpenInfo = false"
      />
      <h5>Реквизиты</h5>
      <div class="select-type" :class="{ error: isNotInfoType }">
        <small>Форма занятости</small>
        <span
          @click="clickOpenInfoType = true"
          v-if="info.type === 1"
          class="select"
        >
          Индивидуальный предпринимать
        </span>
        <span
          @click="clickOpenInfoType = true"
          v-else-if="info.type === 2"
          class="select"
        >
          Самозанятый
        </span>
        <span @click="clickOpenInfoType = true" v-else>Форма занятости</span>
        <div v-if="clickOpenInfoType">
          <span @click="selectInfoType(1)">Индивидуальный предпринимать</span>
          <span @click="selectInfoType(2)">Самозанятый</span>
        </div>
      </div>
      <div class="group-input" :class="{ error: isNotInfoName }">
        <small>Название или ФИО</small>
        <vue-dadata
          v-model="info.name"
          :token="dadataToken"
          :url="dadataUrlFio"
          :debounceWait="'100ms'"
          v-model:suggestion="suggestionFio"
          placeholder="Название или ФИО"
        />
      </div>
      <div class="group-input" :class="{ error: isNotInfoInn }">
        <small>ИНН</small>
        <vue-dadata
          v-model="info.inn"
          :token="dadataToken"
          :url="dadataUrlInn"
          :debounceWait="'100ms'"
          v-model:suggestion="suggestionInn"
          placeholder="ИНН"
        />
      </div>
      <div class="group-input" :class="{ error: isNotInfoBank }">
        <small>Банк</small>
        <vue-dadata
          v-model="info.bank"
          :token="dadataToken"
          :url="dadataUrlBank"
          :debounceWait="'100ms'"
          v-model:suggestion="suggestionBank"
          placeholder="Банк"
        />
      </div>
      <div class="group-input" :class="{ error: isNotInfoNumber }">
        <small>Расчетный счет</small>
        <input
          type="text"
          placeholder="Расчетный счет"
          ref="number"
          v-model="info.number"
        />
      </div>
      <button @click.stop="savePartnerInfo">Сохранить реквизиты</button>
    </div>
  </div>
  <div class="profile-popup partner-info-popup shop-popup" v-if="isOpenPayment">
    <div class="content">
      <img
        src="@/assets/images/shop-close.svg"
        class="close"
        @click.stop="isOpenPayment = false"
      />
      <h5>Заказать выплату</h5>
      <div class="select-payment">
        <span
          @click.stop="paymentType = 'cash'"
          :class="{ active: paymentType === 'cash' }"
        >
          На расчетный счет
          <span v-if="!isPartnerInfo">Реквизиты не указаны</span>
          <span v-else>{{ info.bank }}. Получатель: {{ info.name }}</span>
        </span>
        <span
          @click.stop="paymentType = 'balance'"
          :class="{ active: paymentType === 'balance' }"
        >
          На бонусный счет +3%
          <span>
            Бонусы можно использовать для оплаты покупок на ultrabalance.ru
          </span>
        </span>
      </div>
      <div v-if="paymentType === 'cash' && !isPartnerInfo">
        <p>
          Для вывода вознаграждения на расчетный счет заполните реквизиты ИП или
          Самозанятого
        </p>
        <div class="select-type" :class="{ error: isNotInfoType }">
          <small>Форма занятости</small>
          <span
            @click="clickOpenInfoType = true"
            v-if="info.type === 1"
            class="select"
          >
            Индивидуальный предпринимать
          </span>
          <span
            @click="clickOpenInfoType = true"
            v-else-if="info.type === 2"
            class="select"
          >
            Самозанятый
          </span>
          <span @click="clickOpenInfoType = true" v-else>Форма занятости</span>
          <div v-if="clickOpenInfoType">
            <span @click="selectInfoType(1)">Индивидуальный предпринимать</span>
            <span @click="selectInfoType(2)">Самозанятый</span>
          </div>
        </div>
        <div class="group-input" :class="{ error: isNotInfoName }">
          <small>Название или ФИО</small>
          <vue-dadata
            v-model="info.name"
            :token="dadataToken"
            :url="dadataUrlFio"
            :debounceWait="'100ms'"
            v-model:suggestion="suggestionFio"
            placeholder="Название или ФИО"
          />
        </div>
        <div class="group-input" :class="{ error: isNotInfoInn }">
          <small>ИНН</small>
          <vue-dadata
            v-model="info.inn"
            :token="dadataToken"
            :url="dadataUrlInn"
            :debounceWait="'100ms'"
            v-model:suggestion="suggestionInn"
            placeholder="ИНН"
          />
        </div>
        <div class="group-input" :class="{ error: isNotInfoBank }">
          <small>Банк</small>
          <vue-dadata
            v-model="info.bank"
            :token="dadataToken"
            :url="dadataUrlBank"
            :debounceWait="'100ms'"
            v-model:suggestion="suggestionBank"
            placeholder="Банк"
          />
        </div>
        <div class="group-input" :class="{ error: isNotInfoNumber }">
          <small>Расчетный счет</small>
          <input
            type="text"
            placeholder="Расчетный счет"
            ref="number"
            v-model="info.number"
          />
        </div>
      </div>
      <div class="group-input" :class="{ error: isNotPaymentAmount }">
        <small>Сумма к выводу, руб.</small>
        <input
          type="text"
          placeholder="Сумма к выводу, руб."
          v-model="paymentAmount"
        />
      </div>
      <button @click.stop="savePartnerPayment">Заказать выплату</button>
    </div>
  </div>
  <SuccessTooltipBlock v-if="tooltipDelivery" :header="'Промокод скопирован'" />
  <ErrorTooltipBlock
    v-if="errorTooltip"
    :header="'Ошибка'"
    :text="errorTooltip"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import productMixin from "@/mixins/productMixin";
import profileMixin from "@/mixins/profileMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import SuccessTooltipBlock from "@/components/SuccessTooltipBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import "@/assets/css/personal.css";
import { copyText } from "vue3-clipboard";
import { VueDadata } from "vue-dadata";
import "vue-dadata/dist/style.css";
import api from "@/api";

export default {
  mixins: [mainMixin, pageMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    SuccessTooltipBlock,
    ErrorTooltipBlock,
    VueDadata,
  },
  data() {
    return {
      faq: [{ isOpen: false }, { isOpen: false }],
      promoCode: null,
      promoCodeAmount: "",
      isPartnerInfo: false,
      stories: [],
      payments: [],
      balance: 0,
      waitPayment: 0,
      allPayment: 0,
      info: {},
      isOpenStories: false,
      popupStories: "stories",
      isOpenInfo: false,
      clickOpenInfoType: false,
      isNotInfoType: false,
      isNotInfoInn: false,
      isNotInfoName: false,
      isNotInfoBank: false,
      isNotInfoNumber: false,
      dadataToken: "3027d788a1f31d494c363b6dc6b301dd3b9c4f13",
      dadataUrlFio:
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
      dadataUrlInn:
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party?type=INDIVIDUAL",
      dadataUrlBank:
        "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank",
      suggestionFio: {},
      suggestionInn: {},
      suggestionBank: {},
      isOpenPayment: false,
      paymentType: "cash",
      isNotPaymentAmount: false,
      paymentAmount: "",
      tooltipDelivery: false,
      errorTooltip: null,
      isLoaded: false,
    };
  },
  watch: {
    isOpenStories(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenInfo(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenPayment(isOpen) {
      this.isOpenPopup(isOpen);
    },
    suggestionFio() {
      console.log(this.suggestionFio["data"]["surname"]);
      let name = "";
      if (this.suggestionFio["data"]["surname"]) {
        name += this.suggestionFio["data"]["surname"] + " ";
      }
      if (this.suggestionFio["data"]["name"]) {
        name += this.suggestionFio["data"]["name"] + " ";
      }
      if (this.suggestionFio["data"]["patronymic"]) {
        name += this.suggestionFio["data"]["patronymic"];
      }
      this.info.name = name;
    },
    suggestionInn() {
      this.info.inn = this.suggestionInn["data"]["inn"];
    },
    suggestionBank() {
      this.info.bank = "БИК: " + this.suggestionBank["data"]["bic"] + ", ";
      this.info.bank += this.suggestionBank["data"]["name"]["short"];
    },
  },
  async created() {
    document.title = "Партнерская программа";
    try {
      let result = await api.profile.getPartnerInfo();
      result = result["data"]["results"][0];
      this.balance = result["partner_balance"];
      this.paymentAmount = result["partner_balance"];
      this.payments = result["payments"];
      this.info.bank = result["entity_bank"];
      this.info.inn = result["entity_inn"];
      this.info.name = result["entity_name"];
      this.info.number = result["entity_number"];
      this.info.type = result["entity_type"];
      let count = 0;
      if (this.info.bank && this.info.bank !== "") count += 1;
      if (this.info.inn && this.info.inn !== "") count += 1;
      if (this.info.name && this.info.name !== "") count += 1;
      if (this.info.number && this.info.number !== "") count += 1;
      if (count === 4) this.isPartnerInfo = true;
      if (result["promo_code"].length > 0) {
        this.promoCode = {
          value: result["promo_code"][0]["name"],
          reward: result["promo_code"][0]["reward"],
        };
        if (result["promo_code"][0]["type"] === 1) {
          this.promoCodeAmount = result["promo_code"][0]["amount"] + "%";
        } else {
          this.promoCodeAmount = result["promo_code"][0]["amount"] + " руб.";
        }
      }
      for (let item in this.payments) {
        if (this.payments[item]["status"] === 1) {
          this.waitPayment += this.payments[item]["amount"];
        }
      }
      for (let item in result["stories"]) {
        if (result["stories"][item]["is_wait"]) continue;
        if (result["stories"][item]["type"] === 1) {
          this.allPayment += result["stories"][item]["amount"];
        }
        this.stories.push(result["stories"][item]);
      }
      this.isLoaded = true;
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenStories = false;
        that.isOpenInfo = false;
        that.isOpenPayment = false;
        that.isOpenPopup(false);
      }
    });
  },
  methods: {
    openFaq(num) {
      if (this.faq[num].isOpen) this.faq[num].isOpen = false;
      else this.faq[num].isOpen = true;
    },
    doCopy() {
      copyText(this.promoCode.value, undefined, () => {});
      this.tooltipDelivery = true;
    },
    editComment(item) {
      return item["comment"].replace("[order_id]", item["order_id"]);
    },
    selectInfoType(value) {
      this.clickOpenInfoType = false;
      this.info.type = value;
    },
    getPartnerInfo() {
      let error = false;
      let payload = {};
      if (!this.info.type) {
        this.isNotInfoType = true;
        error = true;
      } else {
        this.isNotInfoType = false;
        payload.type = this.info.type;
      }
      if (!this.info.name || this.info.name === "") {
        this.isNotInfoName = true;
        error = true;
      } else {
        this.isNotInfoName = false;
        payload.name = this.info.name;
      }
      let inn = this.info.inn.replace(/[^\d]/g, "");
      if (inn.length !== 12) {
        this.isNotInfoInn = true;
        error = true;
      } else {
        this.isNotInfoInn = false;
        payload.inn = inn;
      }
      if (!this.info.bank || this.info.bank === "") {
        this.isNotInfoBank = true;
        error = true;
      } else {
        this.isNotInfoBank = false;
        payload.bank = this.info.bank;
      }
      let number = this.info.number.replace(/[^\d]/g, "");
      if (number.length !== 20) {
        this.isNotInfoNumber = true;
        error = true;
      } else {
        this.isNotInfoNumber = false;
        payload.number = number;
      }
      return { error: error, data: payload };
    },
    async savePartnerInfo() {
      let payload = this.getPartnerInfo();
      if (payload.error) return false;
      try {
        await api.profile.putPartnerUpdate(this.profile.id, payload.data);
        location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    async savePartnerPayment() {
      let payload = { error: false, data: {} };
      if (this.paymentType === "cash") {
        payload = this.getPartnerInfo();
        if (!this.paymentAmount || this.paymentAmount < 3000) {
          this.errorTooltip = "Минимальная сумма для вывода 3 000 руб.";
          this.isNotPaymentAmount = true;
          payload.error = true;
        } else {
          this.isNotPaymentAmount = false;
          payload.data.amount = this.paymentAmount;
        }
      } else {
        this.isNotPaymentAmount = false;
        payload.data.amount = this.paymentAmount;
      }
      payload.data.payment = this.paymentType;
      if (payload.error) return false;
      try {
        let result = await api.profile.postCreatePartnerPayment(payload.data);
        result = result["data"];
        if (result["error"]) {
          this.isNotPaymentAmount = true;
          this.errorTooltip = "На балансе недостаточно средств";
        } else location.reload();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
