<template>
  <div class="not-order-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isOpenExistFeedback = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Ранее вы уже оставили отзыв</h5>
      <h6>Поделиться мнением о товаре можно только один раз</h6>
      <button @click="$parent.isOpenExistFeedback = false">
        Понятно, закрыть
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>
