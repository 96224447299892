<template>
  <div class="info">
    <span class="image">
      <img :src="feedback.image.image_object" />
    </span>
    <span class="name">
      <span>{{ feedback.name }}</span>
      <span>{{ feedback.position }}</span>
    </span>
  </div>
  <p>{{ feedback.comment }}</p>
</template>

<script>
export default {
  props: {
    feedback: Object,
  },
};
</script>
