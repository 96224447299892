<template>
  <div class="auth-popup shop-popup">
    <div class="content">
      <span class="light">light</span>
      <img
        v-if="!isCheck"
        @click="$parent.isOpenAuth = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div v-if="isPhone">
        <h5>Введите код из СМС</h5>
        <div class="group-input code" :class="{ error: isErrorCode }">
          <small>4-х значный код-пароль</small>
          <input
            type="tel"
            ref="code"
            v-model="code"
            placeholder="••••"
            maxlength="4"
            @keyup="setFocus"
          />
        </div>
        <span class="send" :class="{ disabled: timer > 0 }" @click="sendRepeat">
          Отправить код повторно
          <span v-if="timer > 0"> ({{ timerText }})</span>
        </span>
        <p v-if="isErrorCode" class="error-code">
          Вы ввели не верный код . Исправьте его, или отправьте новый
        </p>
      </div>
      <div v-else-if="isCheck && !isLoad" class="auth-check-phone">
        <h5>Подтвердите номер телефона</h5>
        <h6>{{ phone }}</h6>
        <div>
          <button @click.stop="confirmPhone">Все верно</button>
          <button @click.stop="isCheck = false">Изменить номер</button>
        </div>
      </div>
      <div v-else-if="!isLoad">
        <h5 v-html="authText"></h5>
        <div class="group-two">
          <div class="select-country">
            <span @click="clickOpenTelCountry">{{ telCountry }}</span>
            <div :class="{ open: isOpenTelCountry }">
              <span @click="selectTelCountry('Россия')">Россия</span>
              <span @click="selectTelCountry('Казахстан')">Казахстан</span>
              <span @click="selectTelCountry('Беларусь')">Беларусь</span>
              <span @click="selectTelCountry('Украина')">Украина</span>
              <span @click="selectTelCountry('Молдавия')">Молдавия</span>
              <span @click="selectTelCountry('Турция')">Турция</span>
              <span @click="selectTelCountry('Болгария')">Болгария</span>
            </div>
          </div>
          <div
            class="group-input"
            :class="{ error: isErrorPhone }"
            @keydown.enter="setCheckPhone"
          >
            <input
              type="tel"
              v-mask="telMask"
              :placeholder="telPlaceholder"
              v-model="phone"
              ref="phone"
              @keyup="clearTel"
            />
          </div>
        </div>
        <button @click.stop="setCheckPhone">Получить код</button>
      </div>
    </div>
  </div>
</template>

<script>
import phoneMixin from "@/mixins/phoneMixin";
import api from "@/api";
import { mask } from "vue-the-mask";
import Const from "@/api/const";

export default {
  mixins: [phoneMixin],
  directives: {
    mask,
  },
  props: {
    authText: String,
    nextAfterAuth: String,
  },
  data() {
    return {
      phone: "",
      code: "",
      isPhone: false,
      isCheck: false,
      isLoad: false,
      isErrorPhone: false,
      productFavorite: 0,
    };
  },
  created() {
    let that = this;
    setTimeout(function () {
      that.$refs["phone"].focus();
    }, 100);
    this.productFavorite = Number(this.$parent.isOpenAuth);
  },
  methods: {
    clickOpenTelCountry() {
      if (this.isOpenTelCountry) this.isOpenTelCountry = false;
      else this.isOpenTelCountry = true;
    },
    getBaskets() {
      let products = [];
      for (let i = 0; i < localStorage.length; i++) {
        let values = localStorage.key(i).split("shop_basketProduct_");
        if (values.length > 1) {
          let quantity = localStorage.getItem(
            "shop_basketProduct_" + values[1]
          );
          let source = localStorage.getItem(
            "shop_basketProductSourcePage_" + values[1]
          );
          let article_id = localStorage.getItem(
            "shop_basketProductArticle_" + values[1]
          );
          try {
            let data = {
              product_id: Number(values[1]),
              quantity: Number(quantity),
            };
            if (source) data.source_page = source;
            if (article_id) data.article_id = article_id;
            let mailingMassId = localStorage.getItem("mailingMassId");
            if (mailingMassId) data.mailing_mass_id = mailingMassId;
            let mailingTriggerId = localStorage.getItem("mailingTriggerId");
            if (mailingTriggerId) data.mailing_trigger_id = mailingTriggerId;
            products.push(data);
          } catch (error) {
            console.log(error);
          }
        }
      }
      return products;
    },
    async confirmPhone() {
      if (this.isLoad) return false;
      this.isLoad = true;
      try {
        let clickHash = localStorage.getItem("clickHash");
        if (!clickHash) clickHash = null;
        let userHash = localStorage.getItem("userHash");
        if (!userHash) userHash = null;
        let payload = {
          phone: this.phone.replace(/[^\d]/g, ""),
          click_hash: clickHash,
          user_hash: userHash,
          products: this.getBaskets(),
        };
        if (this.isPhone) payload["code"] = this.code;
        let result = await api.auth.register(payload);
        result = result["data"];
        if (result["is_code"]) {
          this.isPhone = true;
          let that = this;
          setTimeout(function () {
            that.$refs["code"].focus();
            that.checkCode();
          }, 200);
          this.setTimer();
        }
        if (result["is_registered"]) {
          this.isCheck = false;
          this.$parent.isOpenAuth = false;
          localStorage.setItem("profile_id", result["id"]);
          localStorage.setItem("auth_token", result["auth_token"]);
          localStorage.setItem("profile_firstName", result["first_name"]);
          localStorage.setItem("profile_lastName", result["last_name"]);
          localStorage.setItem("profile_middleName", result["middle_name"]);
          localStorage.setItem("profile_gender", result["gender"]);
          localStorage.setItem("profile_birthday", result["birthday"]);
          localStorage.setItem("profile_email", result["email"]);
          localStorage.setItem("profile_phone", this.phone);
          localStorage.setItem("profile_telegram", result["telegram"]);
          localStorage.setItem("profile_instagram", result["instagram"]);
          localStorage.setItem("profile_vk", result["vk"]);
          localStorage.setItem("profile_comment", result["comment"]);
          let avatar = result["avatar"];
          if (avatar !== "") avatar = Const.API_URL + result["avatar"];
          localStorage.setItem("profile_avatar", avatar);
          if (this.productFavorite > 0) {
            localStorage.setItem("product_favorite", this.productFavorite);
          }
          if (this.nextAfterAuth) {
            window.location.href = this.nextAfterAuth + "?is-auth=1";
          } else window.location.href = location.href + "?is-auth=1";
        }
        this.isLoad = false;
      } catch (error) {
        this.isLoad = false;
        this.isErrorCode = true;
        this.code = "";
        this.$refs.code.focus();
        let that = this;
        setTimeout(function () {
          that.isErrorCode = false;
        }, 5000);
      }
    },
    sendRepeat() {
      if (this.timer === 0) {
        this.code = "";
        this.isPhone = false;
        this.timer = 91;
        this.timerText = "1:31";
        this.confirmPhone();
      }
    },
    setCheckPhone() {
      this.isErrorPhone = false;
      let value = this.phone.replace(/[^\d]/g, "");
      if (value.length > 10) this.isCheck = true;
      else this.isErrorPhone = true;
    },
    checkCode() {
      this.setFocus();
      let that = this;
      setTimeout(function () {
        that.checkCode();
      }, 700);
    },
  },
};
</script>
