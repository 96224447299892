import { createRouter, createWebHistory } from "vue-router";
import mainView from "@/views/MainView.vue";
import LogoutView from "@/views/LogoutView.vue";
import PersonalView from "@/views/PersonalView.vue";
import PersonalSettingsView from "@/views/PersonalSettingsView.vue";
import PersonalDeliveriesView from "@/views/PersonalDeliveriesView.vue";
import PersonalPaymentsView from "@/views/PersonalPaymentsView.vue";
import PersonalOrdersView from "@/views/PersonalOrdersView.vue";
import PersonalReturnsView from "@/views/PersonalReturnsView.vue";
import PersonalLoyaltyProgramView from "@/views/PersonalLoyaltyProgramView.vue";
import PersonalProductsView from "@/views/PersonalProductsView.vue";
import PersonalSubscribersView from "@/views/PersonalSubscribersView.vue";
import PersonalPartnerView from "@/views/PersonalPartnerView.vue";
import CartView from "@/views/CartView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import CatalogView from "@/views/CatalogView.vue";
import PathProductView from "@/views/PathProductView.vue";
import ProductFeedbackView from "@/views/ProductFeedbackView.vue";
import ProductQuestionsView from "@/views/ProductQuestionsView.vue";
import BlogView from "@/views/BlogView.vue";
import PathBlogView from "@/views/PathBlogView.vue";
import TrackerClickView from "@/views/TrackerClickView.vue";
import AutoTrackerView from "@/views/AutoTrackerView.vue";
import PromotionView from "@/views/PromotionView.vue";
import SearchView from "@/views/SearchView.vue";
import PathPageView from "@/views/PathPageView.vue";
import GiftCardView from "@/views/GiftCardView.vue";
import api from "@/api";
import Const from "@/api/const";

async function check_auth_token(next, from, to) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("url_code")) {
    try {
      let payload = {
        url_code: urlParams.get("url_code"),
      };
      let result = await api.auth.register(payload);
      result = result["data"];
      if (result["is_registered"]) {
        localStorage.removeItem("auth_token");
        localStorage.setItem("profile_id", result["id"]);
        localStorage.setItem("auth_token", result["auth_token"]);
        localStorage.setItem("profile_firstName", result["first_name"]);
        localStorage.setItem("profile_lastName", result["last_name"]);
        localStorage.setItem("profile_middleName", result["middle_name"]);
        localStorage.setItem("profile_gender", result["gender"]);
        localStorage.setItem("profile_birthday", result["birthday"]);
        localStorage.setItem("profile_email", result["email"]);
        localStorage.setItem("profile_phone", result["phone"]);
        localStorage.setItem("profile_telegram", result["telegram"]);
        localStorage.setItem("profile_instagram", result["instagram"]);
        localStorage.setItem("profile_vk", result["vk"]);
        localStorage.setItem("profile_comment", result["comment"]);
        localStorage.removeItem("isPageCheckout");
        let avatar = result["avatar"];
        if (avatar !== "") avatar = Const.API_URL + result["avatar"];
        localStorage.setItem("profile_avatar", avatar);
        let href = String(window.location.href).split("?")[0];
        let ooi = urlParams.get("open_order_id");
        if (ooi) href = href + "?open_order_id=" + ooi;
        location.href = href;
      } else next(to);
    } catch (error) {
      next(to);
    }
  } else if (localStorage.getItem("auth_token")) {
    next(from);
  } else {
    next(to);
  }
}

const routes = [
  {
    path: "/",
    name: "main",
    component: mainView,
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/personal",
    name: "personal",
    component: PersonalView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/settings",
    name: "personal_settings",
    component: PersonalSettingsView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/orders",
    name: "personal_orders",
    component: PersonalOrdersView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/returns",
    name: "personal_returns",
    component: PersonalReturnsView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/loyalty-program",
    name: "personal_loyalty_program",
    component: PersonalLoyaltyProgramView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/products",
    name: "personal_products",
    component: PersonalProductsView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/subscribers",
    name: "personal_subscribers",
    component: PersonalSubscribersView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/deliveries",
    name: "personal_deliveries",
    component: PersonalDeliveriesView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/payments",
    name: "personal_payments",
    component: PersonalPaymentsView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/personal/partner",
    name: "personal_partner",
    component: PersonalPartnerView,
    beforeEnter: (to, from, next) => {
      check_auth_token(next, true, "/?is-login=1&next=" + to["fullPath"]);
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutView,
  },
  {
    path: "/catalog",
    name: "catalog",
    component: CatalogView,
  },
  {
    path: "/catalog/:path_catalog",
    name: "path_catalog",
    component: PathProductView,
  },
  {
    path: "/catalog/:path_catalog/feedback",
    name: "product_feedback",
    component: ProductFeedbackView,
  },
  {
    path: "/catalog/:path_catalog/questions",
    name: "product_questions",
    component: ProductQuestionsView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/blog/:path_blog",
    name: "path_blog",
    component: PathBlogView,
  },
  {
    path: "/go/:path_stream",
    name: "path_stream",
    component: TrackerClickView,
  },
  {
    path: "/t/:path_tracking",
    name: "path_tracking",
    component: AutoTrackerView,
  },
  {
    path: "/gift-card/:path_url",
    name: "path_url",
    component: GiftCardView,
  },
  {
    path: "/promo/:path_promotion",
    name: "path_promotion",
    component: PromotionView,
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
  },
  {
    path: "/:path_page",
    name: "path_page",
    component: PathPageView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
