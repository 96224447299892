<template>
  <div v-if="order.destination < 6 && order.is_paid">
    <span
      :class="{
        active: order.status.destination > 0,
        'active-border': order.status.destination > 1,
      }"
    >
      <span>Заказ оформлен</span>
      <span v-if="order.status.destination === 1">
        {{ order.status.name }}
      </span>
    </span>
    <span
      :class="{
        active: order.status.destination > 1,
        'active-border': order.status.destination > 2,
      }"
    >
      <span>В работе</span>
      <span v-if="order.status.destination === 2">
        {{ order.status.name }}
      </span>
    </span>
    <span
      :class="{
        active: order.status.destination > 2,
        'active-border': order.status.destination > 3,
      }"
    >
      <span>В доставке</span>
      <span v-if="order.status.destination === 3">
        {{ order.status.name }}
      </span>
    </span>
    <span
      :class="{
        active: order.status.destination > 3,
        'active-border': order.status.destination > 4,
      }"
    >
      <span>Ожидают получения</span>
      <span v-if="order.status.destination === 4">
        {{ order.status.name }}
      </span>
    </span>
    <span
      :class="{
        active: order.status.destination > 4,
        'active-border': order.status.destination > 5,
      }"
    >
      <span>Доставлен</span>
      <span v-if="order.status.destination === 5">
        {{ order.status.name }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    order: Object,
  },
};
</script>
