<template>
  <div class="thank-popup shop-popup">
    <div class="content content-thank">
      <img
        @click="$parent.isOpenThank = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Спасибо за обращение</h5>
      <h6>
        Служба поддержки обработает обращение и направим ответ на e-mail
        указанный в Вашем профиле
      </h6>
      <button @click="$parent.isOpenThank = false">Понятно, закрыть</button>
    </div>
  </div>
</template>
