<template>
  <div class="error-tooltip">
    <img
      src="@/assets/images/shop-close.svg"
      @click.stop="$parent.errorTooltip = null"
    />
    <h5>{{ header }}</h5>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    text: String,
  },
};
</script>
