import instance from "./instance";
import authModule from "./auth";
import profileModule from "./profile";
import shopModule from "./shop";

export default {
  auth: authModule(instance),
  profile: profileModule(instance),
  shop: shopModule(instance),
};
