<template>
  <div v-if="isTestSiteBlock" class="this-test-site">
    <span>
      Это тестовая версия сайта. Рабочая версия сайта по адресу
      <a href="https://ultrabalance.ru">ultrabalance.ru</a>
      <img
        @click="isTestSiteBlock = false"
        src="@/assets/images/shop-close.svg"
      />
    </span>
  </div>
  <header>
    <div @click="$router.push({ name: 'main' })" class="logo">
      <img :src="headerLogo" />
    </div>
    <div
      class="region-selected"
      @click.stop="isRegionPopup = true"
      v-if="locale.LOCALE === 'ru'"
    >
      <img src="@/assets/images/shop-rus.svg" />
      <span>Россия</span>
    </div>
    <div class="region-selected" @click.stop="isRegionPopup = true" v-else>
      <img src="@/assets/images/shop-uae.svg" />
      <span>ОАЭ</span>
    </div>
    <div class="href-to" v-for="item in headerPages" :key="item.name">
      <a :href="item.url">{{ item.name }}</a>
    </div>
    <div class="href-to" v-if="locale.LOCALE === 'ru'">
      <a href="/personal">
        <img src="@/assets/images/personal.svg" />
        Профиль
      </a>
    </div>
    <div class="href-to">
      <a href="/personal/orders">
        <img src="@/assets/images/orders.svg" />
        {{ locale.TEXTS.orderText }}
      </a>
    </div>
    <div class="href-to basket-button" v-if="locale.LOCALE === 'ru'">
      <router-link to="/cart">
        <img src="@/assets/images/shop-basket.svg" />
        Корзина
        <span>{{ countBasket }}</span>
      </router-link>
    </div>
  </header>
  <NavigationBlock
    :countBasket="countBasket"
    :isHideSearch="isHideSearch"
    v-if="!notNav"
  />
  <div class="region-popup shop-popup" v-if="isRegionPopup">
    <div class="content">
      <img
        @click="isRegionPopup = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>{{ locale.TEXTS.regionPopupH5 }}</h5>
      <div
        class="option"
        :class="{ active: locale.LOCALE === 'ru' }"
        @click.stop="selectRegion('ru')"
      >
        <img src="@/assets/images/shop-rus.svg" />
        <span>
          <span>{{ locale.TEXTS.regionRus }}</span>
          <span>{{ locale.TEXTS.regionRusDesc }}</span>
        </span>
      </div>
      <div
        class="option"
        :class="{ active: locale.LOCALE === 'en' }"
        @click.stop="selectRegion('en')"
      >
        <img src="@/assets/images/shop-uae.svg" />
        <span>
          <span class="desktop">{{ locale.TEXTS.regionUae }}</span>
          <span class="mobile">{{ locale.TEXTS.regionUaeMobile }}</span>
          <span>{{ locale.TEXTS.regionUaeDesc }}</span>
        </span>
      </div>
    </div>
  </div>
  <div class="stay-popup shop-popup" v-if="!isStaySelected">
    <div class="content">
      <img
        @click="isStaySelected = true"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <div v-if="locale.LOCALE === 'ru'">
        <h4>Регион доставки</h4>
        <h5>Delivery region</h5>
        <h6>
          Похоже, что вы находитесь в АОЭ. Хотите переключиться на сайт
          <a href="https://ultrabalance.ae">ultrabalance.ae</a>
          c доставкой по ОАЭ и валютой AED?
        </h6>
        <p>
          It looks like you are in the UAE. Do you want to switch to the website
          <a href="https://ultrabalance.ae">ultrabalance.ae</a>
          with delivery in the UAE and the currency of AED?
        </p>
        <div class="option" @click.stop="selectStay('ultrabalance.ae')">
          <img src="@/assets/images/shop-uae.svg" />
          <span>
            <span>Да, перейти на ultrabalance.ae</span>
            <span>Yes, go to ultrabalance.ae</span>
          </span>
        </div>
        <div class="option" @click.stop="selectStay(null)">
          <img src="@/assets/images/shop-rus.svg" />
          <span>
            <span>Нет, остаться на ultrabalance.ru</span>
            <span>No, stay on ultrabalance.ru</span>
          </span>
        </div>
      </div>
      <div v-else>
        <h4>Delivery region</h4>
        <h5>Регион доставки</h5>
        <h6>
          It looks like you are in Russia. Do you want to switch to the website
          <a href="https://ultrabalance.ru">ultrabalance.ru</a> with delivery in
          the Russia and CIS countries and the currency of RUB?
        </h6>
        <p>
          Похоже, что вы находитесь в России. Хотите переключиться на сайт
          <a href="https://ultrabalance.ru">ultrabalance.ru</a>
          c доставкой по России и странам СНГ и валютой AED?
        </p>
        <div class="option" @click.stop="selectStay('ultrabalance.ru')">
          <img src="@/assets/images/shop-rus.svg" />
          <span>
            <span>Yes, go to ultrabalance.ru</span>
            <span>Да, перейти на ultrabalance.ru</span>
          </span>
        </div>
        <div class="option" @click.stop="selectStay(null)">
          <img src="@/assets/images/shop-uae.svg" />
          <span>
            <span>No, stay on ultrabalance.ae</span>
            <span>Нет, остаться на ultrabalance.ae</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="false"
    :isCheckout="false"
    @tooltipDelivery="tooltipDelivery = $event"
  />
  <SuccessTooltipBlock
    v-if="tooltipDelivery"
    :header="'Адрес сохранен по умолчанию'"
  />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import NavigationBlock from "@/components/NavigationBlock.vue";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import SuccessTooltipBlock from "@/components/SuccessTooltipBlock.vue";
import api from "@/api";
import Const from "@/api/const";
import Locale from "@/api/locale";

export default {
  mixins: [mainMixin],
  components: {
    NavigationBlock,
    AddDeliveryPopupBlock,
    SuccessTooltipBlock,
  },
  props: {
    notNav: Boolean,
    isHideSearch: Boolean,
  },
  data() {
    return {
      isTestSiteBlock: false,
      headerLogo: "",
      headerPages: [],
      countBasket: 0,
      locale: Locale,
      isRegionPopup: false,
      isStaySelected: true,
      isAddDelivery: false,
      deliveriesPoints: [],
      tooltipDelivery: false,
    };
  },
  async created() {
    if (document.location.href.indexOf("?") !== -1) {
      let script = document.createElement("meta");
      script.setAttribute("name", "robots");
      script.setAttribute("content", "noindex, nofollow");
      document.head.appendChild(script);
    }
    if (Const.API_URL !== "https://api.ultrabalance.ru/") {
      this.isTestSiteBlock = true;
    }
    let country = localStorage.getItem("country");
    let city = localStorage.getItem("city");
    if (!country) {
      try {
        const result = await api.shop.getCityInfo();
        country = result["data"]["location"]["data"]["country"];
        city = result["data"]["location"]["data"]["city"];
      } catch (error) {
        country = "Россия";
        city = "Москва";
      }
      localStorage.setItem("country", country);
      localStorage.setItem("ipCountry", country);
      localStorage.setItem("city", city);
      localStorage.setItem("ipCity", city);
      this.checkIsStaySelected(country);
    } else this.checkIsStaySelected(country);
    if (this.authToken) {
      try {
        const result = await api.shop.getShopProductUserBasketCount();
        this.countBasket = result["data"]["count"];
      } catch (error) {
        console.log(error);
      }
    } else {
      for (let i = 0; i < localStorage.length; i++) {
        let values = localStorage.key(i).split("shop_basketProduct_");
        if (values.length > 1) {
          let quantity = localStorage.getItem(
            "shop_basketProduct_" + values[1]
          );
          this.countBasket += Number(quantity);
        }
      }
    }
    try {
      let result = await api.shop.getShopHeader();
      result = result["data"]["results"][0];
      this.headerLogo = result["logo"]["image_object"];
    } catch (error) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("profile_id");
      localStorage.removeItem("profile_firstName");
      localStorage.removeItem("profile_middleName");
      localStorage.removeItem("profile_lastName");
      localStorage.removeItem("profile_birthday");
      localStorage.removeItem("profile_gender");
      localStorage.removeItem("profile_email");
      localStorage.removeItem("profile_phone");
      localStorage.removeItem("profile_avatar");
      localStorage.removeItem("profile_telegram");
      localStorage.removeItem("profile_instagram");
      localStorage.removeItem("profile_vk");
      localStorage.removeItem("profile_comment");
      localStorage.removeItem("isPageCheckout");
      location.reload();
      console.log(error);
    }
  },
  watch: {
    isRegionPopup(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
  methods: {
    async selectRegion(value) {
      if (value === "en" && this.locale.LOCALE === "ru") {
        location.href = "https://ultrabalance.ae";
      } else if (value !== "en" && this.locale.LOCALE === "en") {
        location.href = "https://ultrabalance.ru";
      } else if (value === "ru") {
        this.isRegionPopup = false;
        try {
          const result = await api.shop.getDeliveryPointsMaps();
          this.deliveriesPoints = result["data"]["points"];
          this.isAddDelivery = true;
        } catch (error) {
          console.log(error);
        }
      } else this.isRegionPopup = false;
    },
    selectStay(link) {
      if (link) location.href = "https://" + link;
      else {
        this.isStaySelected = true;
        sessionStorage.setItem("isStaySelected", "1");
      }
    },
    checkIsStaySelected(country) {
      if (!sessionStorage.getItem("isStaySelected")) {
        if (country !== "ОАЭ" && this.locale.LOCALE === "en") {
          this.isStaySelected = false;
        }
        if (country === "ОАЭ" && this.locale.LOCALE === "ru") {
          this.isStaySelected = false;
        }
      }
    },
  },
};
</script>
