<template>
  <img
    v-if="card.paymentName.indexOf('MIR') > -1"
    src="@/assets/images/shop-payment-mir.svg"
  />
  <img
    v-else-if="card.paymentName.indexOf('VISA') > -1"
    src="@/assets/images/shop-payment-visa.svg"
  />
  <img
    v-else-if="card.paymentName.indexOf('MASTERCARD') > -1"
    src="@/assets/images/shop-payment-mastercard.svg"
  />
  <img
    v-else-if="card.paymentName.indexOf('JCB') > -1"
    src="@/assets/images/shop-payment-jcb.svg"
  />
  <img
    v-else-if="card.paymentName.indexOf('UNIONPAY') > -1"
    src="@/assets/images/shop-payment-union.svg"
  />
  <img
    v-else-if="card.paymentName.indexOf('MAESTRO') > -1"
    src="@/assets/images/shop-payment-maestro.svg"
  />
  <img v-else src="@/assets/images/shop-payment-other.svg" />
  {{ card.panEnd }}
</template>
<script>
export default {
  props: {
    card: Object,
  },
};
</script>
