<template>
  <div class="not-order-popup shop-popup">
    <div class="content">
      <img
        @click="$parent.isOpenNotOrder = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Вы не можете оставить отзыв</h5>
      <h6>
        Вы можете писать отзывы только на те продукты, по которым<br />
        ранее совершали покупку
      </h6>
      <button @click="$parent.isOpenNotOrder = false">Понятно, закрыть</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>
