export default {
  data() {
    return {
      authToken: "",
    };
  },
  mounted() {
    if (window.innerWidth < 1200) {
      setTimeout(function () {
        if (!document.querySelector(".personal-footer")) {
          let nav = document.querySelector("nav");
          if (!nav) return false;
          let block = document.querySelector(".subscriber-section");
          if (!block) block = document.querySelector(".footer-content");
          window.addEventListener("scroll", function () {
            let scrollY = window.innerHeight + window.scrollY;
            if (scrollY > block.offsetTop) {
              nav.classList.add("fixed");
              let top = block.offsetTop - 65;
              nav.style.top = top + "px";
            } else nav.classList.remove("fixed");
          });
        }
      }, 1000);
    }
  },
  created() {
    this.authToken = localStorage.getItem("auth_token");
  },
  methods: {
    updateNav() {
      let nav = document.querySelector("nav");
      let block = document.querySelector(".subscriber-section");
      if (!block) block = document.querySelector(".footer-content");
      let scrollY = window.innerHeight + window.scrollY;
      if (scrollY > block.offsetTop) {
        nav.classList.add("fixed");
        let top = block.offsetTop - 65;
        nav.style.top = top + "px";
      } else nav.classList.remove("fixed");
    },
    isOpenPopup(isOpen) {
      if (isOpen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    },
    nextOnCart() {
      window.location.href = "/cart";
    },
  },
};
