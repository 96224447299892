<template>
  <HeaderBlock :isHideSearch="false" />
  <section class="page-section gift-card-section" v-if="giftCard">
    <a class="page-back" href="/catalog">
      <span>{{ locale.TEXTS.backCatalog }}</span>
    </a>
    <div class="main-wrap">
      <h1>Цифровая подарочная карта</h1>
      <p v-if="giftCard.comment">{{ giftCard.comment }}</p>
      <div
        class="image"
        :style="{
          background: giftCard.nominal_images[0].image.background_color,
        }"
      >
        <img :src="giftCard.nominal_images[0].image.image_object_big" />
      </div>
      <h3>Информация о сертификате</h3>
      <ul>
        <li>Номинал: {{ giftCard.nominal_value }} рублей.</li>
        <li v-if="giftCard.status === 3">Статус: активирован</li>
        <li v-else>Статус: не активирован</li>
        <li>Тип активации: по промокоду</li>
        <li>Номер сертификата: {{ giftCard.id }}</li>
      </ul>
      <h3>Как активировать сертификат</h3>
      <ol>
        <li>Нажмите на кнопку “Получить промокод” и активируйтесь.</li>
        <li>
          Добавьте товары в корзину и активируйте промокод в корзине или при
          оформлении заказа.
        </li>
        <li>
          Если номинал сертификата будет меньше, чем сумма заказа, то необходимо
          будет доплатить разницу. Если номинал сертификата больше, чем сумма
          заказа, то непотраченная сумма сгорит.
        </li>
      </ol>
      <button
        class="get-promo-code"
        v-if="!isGetPromoCode"
        @click.stop="getPromoCode"
      >
        Получить промокод
      </button>
      <button class="promo-code" @click="doCopy" v-if="isGetPromoCode">
        {{ giftCard.promo_code.name }}
      </button>
      <span v-if="isCopy">Промокод скопирован</span>
    </div>
  </section>
  <FooterBlock />
  <AuthRegBlock v-if="isOpenAuth" :authText="authText" />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import AuthRegBlock from "@/components/AuthRegBlock.vue";
import api from "@/api";
import Locale from "@/api/locale";
import { copyText } from "vue3-clipboard";
import "@/assets/css/blog.css";

export default {
  mixins: [mainMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    AuthRegBlock,
  },
  data() {
    return {
      locale: Locale,
      giftCard: null,
      isGetPromoCode: false,
      isCopy: false,
      isOpenAuth: false,
      authText: "",
    };
  },
  async mounted() {
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isOpenPopup(false);
      }
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("is-auth")) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
    document.title = "Цифровая подарочная карта";
    document.getElementsByTagName("meta")["description"].content =
      "Цифровая подарочная карта";
    try {
      let path_url = this.$route.params.path_url;
      const result = await api.shop.getGiftCardFromPath(path_url);
      let giftCard = result["data"]["results"][0];
      if (giftCard) this.giftCard = giftCard;
      else window.location.href = "/";
    } catch (error) {
      window.location.href = "/";
    }

    try {
      let payload = {
        gift_card_id: this.giftCard.id,
      };
      await api.shop.getGiftCardView(payload);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    getPromoCode() {
      if (this.authToken) {
        this.isGetPromoCode = true;
        copyText(this.giftCard.promo_code.name, undefined, () => {});
      } else {
        this.isOpenAuth = true;
        this.authText =
          "Войдите или зарегистрируйтесь,<br /> чтобы получить промокод";
      }
    },
    doCopy() {
      copyText(this.giftCard.promo_code.name, undefined, () => {});
      this.isCopy = true;
      let that = this;
      setTimeout(function () {
        that.isCopy = false;
      }, 2000);
    },
  },
};
</script>
