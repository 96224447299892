<template>
  <div class="personal-header">
    <HeaderBlock :isHideSearch="false" />
  </div>
  <section class="personal-section">
    <PersonalLeftBlock :hrefTo="'orders'" />
    <div class="block-right">
      <a @click="$router.push({ name: 'personal' })" class="back">В профиль</a>
      <h1>
        Мои заказы
        <span>{{ orders.length }}</span>
      </h1>
      <div class="destination-tabs">
        <span
          :class="{ active: destinationValue === 0 }"
          @click.stop="destinationValue = 0"
        >
          Все
        </span>
        <span
          :class="{ active: destinationValue === -1 }"
          @click.stop="destinationValue = -1"
        >
          Ожидают оплаты
        </span>
        <span
          :class="{ active: destinationValue === 1 }"
          @click.stop="destinationValue = 1"
        >
          Заказ оформлен
        </span>
        <span
          :class="{ active: destinationValue === 2 }"
          @click.stop="destinationValue = 2"
        >
          В работе
        </span>
        <span
          :class="{ active: destinationValue === 3 }"
          @click.stop="destinationValue = 3"
        >
          В доставке
        </span>
        <span
          :class="{ active: destinationValue === 4 }"
          @click.stop="destinationValue = 4"
        >
          Ожидают получения
        </span>
        <span
          :class="{ active: destinationValue === 5 }"
          @click.stop="destinationValue = 5"
        >
          Доставлен
        </span>
        <span
          :class="{ active: destinationValue === 6 }"
          @click.stop="destinationValue = 6"
        >
          Отменённые
        </span>
        <span
          :class="{ active: destinationValue === 7 }"
          @click.stop="destinationValue = 7"
        >
          Возвраты
        </span>
      </div>
      <div
        v-for="item in ordersDisplay"
        :key="item"
        class="order-item"
        @click.stop="(item.isOpen = true), isOpenPopup(true)"
      >
        <OrderBlock :order="item" />
      </div>
    </div>
  </section>
  <div v-for="item in ordersDisplay" :key="item">
    <div v-if="item.isOpen" class="order-popup shop-popup">
      <div class="content">
        <img
          @click="(item.isOpen = false), isOpenPopup(false)"
          src="@/assets/images/shop-close.svg"
          class="close"
        />
        <h5>Заказ {{ item.id }}</h5>
        <h6>от {{ dateFormatString(item.created_at) }}</h6>
        <div class="block">
          <div class="block-item">
            <span>Статус заказа</span>
            <div>
              {{ item.status.name }}
              <div>
                <span>Подробнее</span>
                <OrderStatusesBlock :order="item" />
              </div>
            </div>
          </div>
          <div class="block-item">
            <span>Статус оплаты</span>
            <span v-if="item.is_paid">Оплачен</span>
            <span v-else>Не оплачен</span>
            <a
              class="price"
              v-if="item.status.widgets.length > 0"
              @click.stop="generatorPaymentOrder(item.id)"
            >
              Оплатить {{ formatPrice(item.total) }} руб.
            </a>
          </div>
        </div>
        <div class="block">
          <div class="block-item" v-if="item.payment_card">
            <span>Оплата картой {{ item.payment_card.bank_name }}</span>
            <span>
              {{ item.payment_card.pan }} ({{ item.payment_card.payment_name }})
            </span>
          </div>
          <div class="block-item">
            <span>{{ profile.firstName }} {{ profile.lastName }}</span>
            <span v-if="profile.email">
              {{ profile.phone }}; {{ profile.email }}
            </span>
            <span v-else>{{ profile.phone }}</span>
          </div>
          <div class="block-item" v-if="item.delivery_type">
            <span v-if="item.delivery_type === 1">
              Пункт самовывоза {{ item.delivery_service_name }}
            </span>
            <span v-else-if="item.delivery_type === 3">
              Почтомат {{ item.delivery_service_name }}
            </span>
            <span v-else>
              Доставка курьером {{ item.delivery_service_name }}
            </span>
            <span>{{ item.delivery_address }}</span>
          </div>
        </div>
        <div class="products">
          <a
            v-for="product in item.products"
            :key="product"
            :href="getUrlProduct(item, product)"
            target="_blank"
            class="product-item"
          >
            <div
              class="image"
              :style="{
                background: product.product.images[0].image.background_color,
              }"
            >
              <img :src="product.product.images[0].image.image_object_small" />
            </div>
            <div class="info" v-if="product.product.type === 5">
              <span class="name">
                Пластиковая подарочная карта на
                {{ product.product.headline_preview }} руб.
              </span>
              <span class="total" v-if="item.is_paid">
                ultrabalance.ru/gift-card/{{ product.gift_card_url }}
              </span>
            </div>
            <div class="info" v-else>
              <span class="name">
                {{ product.product.headline_preview }}
              </span>
              <span class="total">
                {{ formatPrice(product.total / product.quantity) }} руб. х
                {{ product.quantity }} шт.
              </span>
            </div>
          </a>
          <a
            v-for="product in item.virtual_products"
            :key="product"
            :href="getUrlProduct(item, product)"
            target="_blank"
            class="product-item"
          >
            <div
              class="image"
              :style="{
                background: product.product.images[0].image.background_color,
              }"
            >
              <img :src="product.product.images[0].image.image_object_small" />
            </div>
            <div class="info">
              <span class="name">
                Электронная подарочная карта на
                {{ product.product.headline_preview }} руб.
              </span>
              <span class="total" v-if="item.is_paid">
                ultrabalance.ru/gift-card/{{ product.gift_card_url }}
              </span>
            </div>
          </a>
        </div>
        <div class="total">
          <div class="item">
            <span>Стоимость заказа:</span>
            <span>{{ getTotalProducts(item) }} руб.</span>
          </div>
          <div v-if="item.promo_code" class="item discount">
            <span>Промокод {{ item.promo_code.name }}:</span>
            <span>-{{ formatPrice(item.promo_code_amount) }} руб.</span>
          </div>
          <div class="item">
            <span>Стоимость доставки:</span>
            <span>{{ formatPrice(item.delivery_price) }} руб.</span>
          </div>
          <div class="item">
            <span>Итоговая сумма:</span>
            <span>{{ formatPrice(item.total) }} руб.</span>
          </div>
        </div>
        <div class="buttons">
          <a
            v-if="item.tinkoff_ofd_url"
            :href="item.tinkoff_ofd_url"
            target="_blank"
          >
            <button>Скачать чек</button>
          </a>
          <a
            v-if="!item.tinkoff_ofd_url && item.status.widgets.length > 0"
            target="_blank"
            @click.stop="cancelOrder(item.id)"
          >
            <button>Отменить заказ</button>
          </a>
          <a
            v-if="item.status.destination === 5"
            @click.stop="repeatOrder(item.id)"
          >
            <button>Повторить заказ</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-if="successOrder" class="success-order-popup shop-popup">
    <div class="content">
      <img
        @click="closeSuccessOrder"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Заказ успешно оформлен и оплачен</h5>
      <div class="order-item" @click.stop="openSuccessOrder">
        <OrderBlock :order="successOrder" />
      </div>
      <h6>Рекомендуем к прочтению</h6>
      <div class="articles-items">
        <div v-for="item in articles" :key="item" class="article-item">
          <ArticleItemBlock :item="item" />
        </div>
      </div>
    </div>
  </div>
  <div class="personal-footer">
    <FooterBlock />
  </div>
  <div v-if="errorPayment" class="error-popup shop-popup">
    <div class="content">
      <img
        @click="errorPayment = null"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Ошибка</h5>
      <p>{{ errorPayment }}</p>
    </div>
  </div>
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import pageMixin from "@/mixins/pageMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import PersonalLeftBlock from "@/components/PersonalLeftBlock.vue";
import OrderBlock from "@/components/OrderBlock.vue";
import OrderStatusesBlock from "@/components/OrderStatusesBlock.vue";
import ArticleItemBlock from "@/components/ArticleItemBlock.vue";
import api from "@/api";
import "@/assets/css/personal.css";
import Const from "@/api/const";

export default {
  mixins: [mainMixin, pageMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    PersonalLeftBlock,
    OrderBlock,
    OrderStatusesBlock,
    ArticleItemBlock,
  },
  data() {
    return {
      destinationValue: 0,
      orders: [],
      ordersDisplay: [],
      successOrder: null,
      articles: [],
      errorPayment: null,
    };
  },
  async created() {
    document.title = "Мои заказы";
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.closeSuccessOrder();
        for (let item in that.ordersDisplay) {
          that.ordersDisplay[item].isOpen = false;
        }
        that.errorPayment = null;
        that.isOpenPopup(false);
      }
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get("error");
    if (error) {
      this.errorPayment = error;
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
    const orderId = urlParams.get("order_id");
    const openOrderId = urlParams.get("open_order_id");
    const token = urlParams.get("token");
    const isToken = urlParams.get("isToken");
    if (isToken && orderId) {
      try {
        const result = await api.auth.getProfile();
        localStorage.setItem("profile_id", result["data"]["id"]);
        localStorage.setItem("auth_token", token);
        localStorage.setItem("profile_firstName", result["data"]["first_name"]);
        localStorage.setItem("profile_lastName", result["data"]["last_name"]);
        localStorage.setItem(
          "profile_middleName",
          result["data"]["middle_name"]
        );
        localStorage.setItem("profile_gender", result["data"]["gender"]);
        localStorage.setItem("profile_birthday", result["data"]["birthday"]);
        localStorage.setItem("profile_email", result["data"]["email"]);
        localStorage.setItem("profile_phone", result["data"]["phone"]);
        localStorage.setItem("profile_telegram", result["data"]["telegram"]);
        localStorage.setItem("profile_instagram", result["data"]["instagram"]);
        localStorage.setItem("profile_vk", result["data"]["vk"]);
        localStorage.setItem("profile_comment", result["data"]["comment"]);
        let avatar = result["data"]["avatar"];
        if (avatar !== "") avatar = Const.API_URL + result["data"]["avatar"];
        localStorage.setItem("profile_avatar", avatar);
        window.location.href = "/personal/orders?order_id=" + orderId;
      } catch (error) {
        console.log(error);
      }
    } else if (token && orderId) {
      localStorage.setItem("auth_token", token);
      window.location.href =
        "/personal/orders?order_id=" + orderId + "&isToken=1";
    }
    if (!this.authToken) window.location.href = "/";
    try {
      const result = await api.profile.getUserOrders({});
      this.orders = result["data"]["results"];
      this.reloadOrdersDisplay();
      if (orderId || openOrderId) {
        for (let item in this.orders) {
          if (Number(openOrderId) === this.orders[item]["id"]) {
            this.orders[item].isOpen = true;
            this.isOpenPopup(true);
            break;
          } else if (Number(orderId) === this.orders[item]["id"]) {
            this.successOrder = this.orders[item];
            await this.getArticles();
            this.isOpenPopup(true);
            break;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    destinationValue() {
      this.reloadOrdersDisplay();
    },
    errorPayment(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
  methods: {
    getUrlProduct(order, product) {
      let url = "";
      if (product.product.type === 5 || product.product.type === 6) {
        if (order.is_paid) url = "/gift-card/" + product.gift_card_url;
        else if (product.product.type === 5) url = "/catalog/gift-card";
        else if (product.product.type === 6) url = "/catalog/gift-card";
      } else url = "/catalog/" + product.product.shop_page[0].url;
      return url;
    },
    reloadOrdersDisplay() {
      this.ordersDisplay = [];
      if (this.destinationValue === 0) {
        this.ordersDisplay = this.orders;
        return false;
      }
      for (let item in this.orders) {
        item = this.orders[item];
        if (this.destinationValue === -1 && !item["is_paid"]) {
          this.ordersDisplay.push(item);
        } else if (this.destinationValue === item["status"]["destination"]) {
          this.ordersDisplay.push(item);
        }
      }
    },
    getTotalProducts(order) {
      let value = order["total"] - order["delivery_price"];
      if (order["promo_code_amount"]) value += order["promo_code_amount"];
      return this.formatPrice(value);
    },
    closeSuccessOrder() {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
      this.successOrder = null;
      this.isOpenPopup(false);
    },
    async getArticles() {
      try {
        const result = await api.shop.getOrderOfBlogArticles(
          this.successOrder["id"]
        );
        this.articles = result["data"]["results"];
      } catch (error) {
        console.log(error);
      }
    },
    openSuccessOrder() {
      let id = this.successOrder.id;
      this.closeSuccessOrder();
      for (let item in this.orders) {
        if (this.orders[item].id === id) {
          this.orders[item].isOpen = true;
          this.isOpenPopup(true);
          break;
        }
      }
    },
    async generatorPaymentOrder(id) {
      try {
        const result = await api.shop.postShopGeneratorPaymentOrder(id);
        window.location = result["data"]["url"];
      } catch (error) {
        console.log("Ошибка");
      }
    },
    async repeatOrder(id) {
      try {
        await api.shop.postShopRepeatOrder(id);
        window.location = "/checkout";
      } catch (error) {
        console.log("Ошибка");
      }
    },
    async cancelOrder(id) {
      try {
        await api.shop.postShopCancelOrder(id);
        location.reload();
      } catch (error) {
        console.log("Ошибка");
      }
    },
  },
};
</script>
