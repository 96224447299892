<template>
  <SearchCatalogPage
    v-if="searchSessionHash"
    :count="count"
    :query="query"
    :searchSessionHash="searchSessionHash"
  />
</template>

<script>
import SearchCatalogPage from "@/pages/SearchCatalogPage.vue";
import api from "@/api";

export default {
  components: {
    SearchCatalogPage,
  },
  data() {
    return {
      count: 0,
      rawQuery: "",
      query: "",
      searchSessionHash: "",
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    let userHash = localStorage.getItem("userHash");
    if (!userHash) userHash = null;
    this.rawQuery = urlParams.get("query");
    let query = this.clearQuery(urlParams.get("query"));
    let payload = {
      user_hash: userHash,
      raw_query: this.rawQuery,
      query: query,
    };
    try {
      const result = await api.shop.postShopSearchSession(payload);
      this.count = result["data"]["count"];
      this.query = result["data"]["query"];
      this.searchSessionHash = result["data"]["search_session_hash"];
      localStorage.setItem("userHash", result["data"]["user_hash"]);
      sessionStorage.setItem("searchSessionHash", this.searchSessionHash);
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    clearQuery(value) {
      value = value.toLowerCase();
      value = value.replaceAll("-", " ");
      value = value.replace(/[^a-zA-Zа-яА-Я0-9 ]/gi, "");
      value = value.replace(/ {1,}/g, " ").trim();
      return value;
    },
  },
};
</script>
