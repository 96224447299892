// const API_URL = "http://127.0.0.1:8000/";
// const API_URL = "https://api.ubalance.ru/";
const API_URL = "https://api.ultrabalance.ru/";

const SITE_ID = 1;
// const SITE_ID = 2;

const LOCALE = "ru";
// const LOCALE = "en";

export default {
  API_URL: API_URL,
  LOCALE: LOCALE,
  SITE_ID: SITE_ID,
};
