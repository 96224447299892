<template>
  <div class="success-tooltip">
    <h5>{{ header }}</h5>
    <button @click.stop="$parent.tooltipDelivery = false">
      <span :style="{ width: width }"></span>
      <span>Ок</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    header: String,
    text: String,
  },
  data() {
    return {
      second: 0,
      width: "0%",
    };
  },
  created() {
    this.tooltipTimer();
  },
  methods: {
    tooltipTimer() {
      this.second += 5;
      this.width = (this.second * 100) / 2000;
      this.width = this.width + "%";
      if (this.second < 2000) setTimeout(this.tooltipTimer, 5);
      else this.$parent.tooltipDelivery = false;
    },
  },
};
</script>
