<template>
  <a class="notification-continue" v-if="isPageCheckout" href="/checkout">
    <span>Завершите оформление заказа:</span>
    <div>
      <a
        v-for="product in products"
        :key="product"
        :style="{
          background: product.images[0].image.background_color,
        }"
      >
        <img :src="product.images[0].image.image_object_small" />
        <span v-if="productsCount > 0">+{{ productsCount }}</span>
      </a>
    </div>
    <a>Перейти к оформлению</a>
  </a>
  <a
    class="notification-continue"
    v-else-if="orders.length > 0"
    :href="'/personal/orders?open_order_id=' + orders[0].id"
  >
    <span>Завершите оплату заказа №{{ orders[0].id }}:</span>
    <div>
      <a
        v-for="product in orders[0].products"
        :key="product"
        :style="{
          background: product.product.images[0].image.background_color,
        }"
      >
        <img :src="product.product.images[0].image.image_object_small" />
        <span v-if="productsCount > 0">+{{ productsCount }}</span>
      </a>
    </div>
    <a>Перейти к заказу</a>
  </a>
</template>

<script>
import api from "@/api";

export default {
  props: {
    authToken: String,
  },
  data() {
    return {
      isPageCheckout: false,
      orders: [],
      products: [],
      productsCount: 0,
    };
  },
  async created() {
    if (this.authToken && this.authToken !== "") {
      if (localStorage.getItem("isPageCheckout")) {
        this.isPageCheckout = true;
        try {
          const result = await api.shop.getShopProductUserBaskets();
          for (let item in result["data"]["results"]) {
            let product = result["data"]["results"][item];
            this.products.push({
              images: product["product"]["images"],
            });
          }
          if (this.products.length === 0) {
            this.isPageCheckout = false;
            localStorage.removeItem("isPageCheckout");
          }
          this.productsCount = this.products.length - 2;
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let data = {
            is_not_paid: true,
          };
          const result = await api.profile.getUserOrders(data);
          this.orders = result["data"]["results"];
          if (this.orders.length > 0) {
            this.productsCount = this.orders[0].products.length - 2;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
};
</script>
