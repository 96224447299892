<template>
  <footer class="footer-min">
    <div class="footer-content">
      <div class="section-first">
        <a class="logo" href="/"><img :src="footerLogo" /></a>
        <span class="description about">{{ footerAbout }}</span>
      </div>
      <div class="section-second">
        <span class="description requisites">{{ footerRequisites }}</span>
        <span class="description">{{ footerAddress }}</span>
      </div>
      <div class="section-third">
        <span class="description phone">{{ footerPhone }}</span>
        <span class="description email">{{ footerEmail }}</span>
      </div>
      <div class="section-fourth">
        <span class="href-to" v-for="item in footerPages" :key="item.name">
          <a target="_blank" :href="item.url">{{ item.name }}</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      footerLogo: "",
      footerAbout: "",
      footerAddress: "",
      footerEmail: "",
      footerPhone: "",
      footerRequisites: "",
      footerPages: [],
    };
  },
  async created() {
    try {
      let result = await api.shop.getShopFooter();
      result = result["data"]["results"][0];
      this.footerLogo = result["logo"]["image_object"];
      this.footerAbout = result["about"];
      this.footerAddress = result["address"];
      this.footerEmail = result["email"];
      this.footerPhone = result["phone"];
      this.footerRequisites = result["requisites"];
      for (let item in result["pages"]) {
        item = result["pages"][item];
        if (item["section"] !== 1) continue;
        let url = item["external_reference"];
        if (item["page"]) {
          url = "";
          if (item["page"]["type"] === 3) url = "/catalog";
          if (item["page"]["type"] === 4) url = "/blog";
          if (item["page"]["type"] === 5) url = "/blog";
          if (item["page"]["type"] === 6) url = "/catalog";
          url += "/" + item["page"]["url"];
        }
        this.footerPages.push({
          name: item["name"],
          url: url,
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
